import React, { Component, useEffect } from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import {
  createListing,
  getHeaders,
  loadData,
  updateHeadersComplete,
  updatePageMeta,
  setPageHeaders,
} from './AdminPanelPage.duck';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Redirect, withRouter } from 'react-router-dom';
import css from './AdminPage.module.css';
import AdminPanelSearchPanelComponent from './AdminPanelSearchPanel/AdminPanelSearchPanelComponent';
import AdminPanelPageListingComponent from './AdminPanelPageListing/AdminPanelPageListingComponent';
import AdminPanelPageHeadersComponent from './AdminPanelPageHeaders/AdminPanelPageHeadersComponent';
import AdminPanelPageUserComponent from './AdminPanelPageUser/AdminPanelPageUserComponent';
import Pagination from './AdminPanelPagePagination/AdminPanelPagePagination.component';
import { AddButtonComponent } from '../../components/AddButton/AddButton.component';

import { NotFoundPage, StaticPage, TopbarContainer } from '../../containers';

import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  AdminNav,
} from '../../components';
import SearchConfigModal from '../../components/SearchConfigModal/SearchConfigModal';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

const mapStateToProps = state => {
  const { isAdmin, currentUser } = state.user;
  const {
    listings,
    headers,
    sort,
    searchValue,
    pageMeta,
    fetchInProgress,
    category,
    perPage,
    showAll,
    status,
    users,
    fetchUsersInProgress,
    fetchUsersError,
    usersPageMeta,
    updateListingsTransactionsCountInProgress,
    updateListingsTransactionsCountSuccess,
    updateListingsTransactionsCountError,
  } = state.AdminPanelPage;
  return {
    listings,
    headers,
    sort,
    searchValue,
    pageMeta,
    fetchInProgress,
    category,
    perPage,
    showAll,
    status,
    isAdmin,
    currentUser,
    users,
    fetchUsersInProgress,
    fetchUsersError,
    usersPageMeta,
    updateListingsTransactionsCountInProgress,
    updateListingsTransactionsCountSuccess,
    updateListingsTransactionsCountError,
  };
};

const mapDispatchToProps = {
  loadData: loadData,
  updatePageMeta: updatePageMeta,
  updateHeadersComplete: updateHeadersComplete,
  onSetPageHeaders: setPageHeaders,
};

class AdminPanelPageComponent extends Component {
  disabled = false;
  componentDidMount() {
    const {
      searchValue,
      sort,
      category,
      perPage,
      pageMeta,
      showAll,
      status,
      intl,
      headers,
      params,
    } = this.props;
    this.props.loadData({
      searchValue,
      sort,
      page: pageMeta.page,
      category,
      perPage,
      showAll,
      status,
      pageId: params.pageId,
    });
    const localStorageHeaders =
      localStorage.getItem('headers') && JSON.parse(localStorage.getItem('headers'));

    if (localStorageHeaders) {
      this.props.updateHeadersComplete({ items: localStorageHeaders });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      searchValue,
      sort,
      pageMeta,
      category,
      perPage,
      showAll,
      status,
      headers,
      params,
    } = this.props;
    if (
      searchValue !== prevProps.searchValue ||
      sort !== prevProps.sort ||
      pageMeta.page !== prevProps.pageMeta.page ||
      this.props.category !== prevProps.category ||
      perPage !== prevProps.perPage ||
      showAll !== prevProps.showAll ||
      status !== prevProps.status
    ) {
      this.props.loadData({
        searchValue,
        sort,
        page: pageMeta.page,
        category,
        perPage,
        showAll,
        status,
        pageId: params.pageId,
      });
    }
  }

  render() {
    const self = this;
    function handleAddClick() {
      self.disabled = true;
      async function createListingAsync() {
        const newListing = await createListing({})();
        history.push(
          `l/${newListing.data.data.data.attributes.title}/${newListing.data.data.data.id.uuid}/edit/description`
        );
      }

      createListingAsync();
    }
    function onClose() {
      self.props.updatePageMeta({ searchModalOpen: false });
    }
    const {
      history,
      headers,
      sort,
      fetchInProgress,
      isAdmin,
      intl,
      currentUser,
      status,
      category,
      params,
      showAll,
      updateListingsTransactionsCountInProgress,
      updateListingsTransactionsCountSuccess,
      updateListingsTransactionsCountError,
    } = this.props;
    if (!isAdmin && currentUser) {
      return <NotFoundPage />;
    }
    const { pageId } = params || {};
    const isUsersPage = pageId === 'users';
    let slot1 = isUsersPage
      ? this.props.users.map(user => (
          <AdminPanelPageUserComponent user={user} key={user.id.uuid} headers={headers} />
        ))
      : this.props.listings.map(listing => (
          <AdminPanelPageListingComponent
            listing={listing}
            key={listing.id.uuid}
            headers={headers}
            intl={intl}
          />
        ));

    if (slot1.length === 0) {
      slot1 = (
        <tr>
          <td>NO RESULTS</td>
        </tr>
      );
    }

    const heading = isUsersPage
      ? intl.formatMessage({
          id: 'AdminPanelPage.usersHeading',
        })
      : intl.formatMessage({
          id: 'AdminPanelPage.listingsHeading',
        });

    return (
      <StaticPage
        title="Admin Panel | TRiO Plus"
        schema={{
          '@context': 'http://schema.org',
          '@type': 'AdminPanelPage',
          description: 'Admin Panel TRiO Plus',
          name: 'Admin page',
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
            <AdminNav selectedPageName="AdminPanelListings" params={params} />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain className={css.staticPageWrapper}>
            <div className={css.adminPanel}>
              <div className={`${css.flex} ${css.spaceBetween} ${css.AlignCenter}`}>
                <h1>{heading}</h1>
              </div>
              <AdminPanelSearchPanelComponent
                data={isUsersPage ? this.props.users : this.props.listings}
                isUsersPage={isUsersPage}
                sort={sort}
                status={status}
                category={category}
                updateListingsTransactionsCountInProgress={updateListingsTransactionsCountInProgress}
                updateListingsTransactionsCountSuccess={updateListingsTransactionsCountSuccess}
                updateListingsTransactionsCountError={updateListingsTransactionsCountError}
                intl={intl}
              />
              <table className={css.adminPanelSearchSectionTable}>
                <thead>
                  <AdminPanelPageHeadersComponent headers={headers} isUsersPage={isUsersPage} />
                </thead>
                <tbody className={fetchInProgress ? css.loading : ''}>{slot1}</tbody>
              </table>
              {
                showAll ? null :
                <Pagination isUsersPage={isUsersPage} />
              }
            </div>

            {/* <SearchConfigModal headers={headers} id="SearchConfig" onCloseModal={onClose} ></SearchConfigModal> */}
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    );
  }
}

const AdminPanelPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AdminPanelPageComponent);

export default AdminPanelPage;
