import React, { useRef } from 'react';
import { DragSource, DropTarget } from 'react-dnd';

import css from './SortableItem.module.css';

const itemType = 'item';

const SortableItem = ({
  id,
  data,
  index,
  moveItem,
  toggleIsOn,
  connectDragSource,
  connectDropTarget,
  isDragging,
}) => {
  const onToggle = () => {
    toggleIsOn(id);
  };

  return connectDragSource(
    connectDropTarget(
      <div
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: 'move',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
          padding: '10px',
          border: '1px solid #ccc',
        }}
        className={css.adminPanelHeaderVisibilty}
      >
        <div>{data.label}</div>
        <div>
          <label htmlFor={`toggle-${id}`}>
            <input
              id={`toggle-${id}`}
              type="checkbox"
              checked={data.isOn}
              onChange={onToggle}
            />
            {/* On/Off */}
          </label>
        </div>
      </div>,
    ),
  );
};

const dragSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const dropTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) return;

    console.log('dragIndex', dragIndex);
    console.log('hoverIndex', hoverIndex);
    props.moveItem(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

export default DropTarget(
  itemType,
  dropTarget,
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    itemType,
    dragSource,
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(SortableItem),
);
