import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import { NamedLink, ResponsiveImage, IconDiscount, IconInfo } from '../../components';

import css from './ListingCard.module.css';

const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  const formattedPrice = formatMoney(intl, price);
  return { formattedPrice, priceTitle: price };
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    isMobileLayout,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const { addDiscountIcon, tags = [] } = publicData || {};
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const filterConfig = config.custom.filters;
  const tagOptions = findOptionsForSelectFilter('tags', filterConfig);
  const listingTags =
    !!tags && tags.length > 0 ? (
      <div className={css.tags}>
        {tags.map(tag => {
          const tagLabel = tagOptions.find(t => t.key === tag)?.label;
          const tagLabelTranslated = !!tagLabel && intl.formatMessage({ id: tagLabel });
          const background = intl.formatMessage({ id: `ListingCard.tags.${tag}.backgroundColor` });
          const textColor = intl.formatMessage({ id: `ListingCard.tags.${tag}.textColor` });
          const hasToolTip = !!intl.messages[`ListingCard.tags.${tag}.info`];
          const tagInfo = hasToolTip
            ? intl.formatMessage({ id: `ListingCard.tags.${tag}.info` })
            : null;
          return (
            <div
              className={css.tag}
              style={{ background: `${background}`, color: `${textColor}` }}
              data-tooltip-id={`tag-${tag}`}
              data-tooltip-content={tagInfo}
              onClick={e => e.preventDefault()}
            >
              <span>{tagLabelTranslated}</span>
              {hasToolTip ? (
                <>
                  <span className={css.infoIcon}>
                    <IconInfo />
                  </span>
                </>
              ) : null}
            </div>
          );
        })}
      </div>
    ) : null;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          {!!addDiscountIcon?.includes(true) ? <IconDiscount className={css.iconDiscount} /> : null}
          {listingTags}
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice.split('.')[0]}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.authorInfo}>
            <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  // Responsive image sizes hint
  renderSizes: string,
  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
