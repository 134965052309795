import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImageDiscount from './ImageDiscount.png'
import css from './IconDiscount.module.css';

const IconDiscount = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <img src={ImageDiscount} className={classes} />
  );
};

const { string } = PropTypes;

IconDiscount.defaultProps = {
  className: null,
  rootClassName: null,
};

IconDiscount.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconDiscount;
