import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import classNames from 'classnames';
import Select from 'react-select';
import flatten from 'lodash/flatten';
import { pickSearchParamsOnly } from '../../containers/SearchPage/SearchPage.helpers';
import omit from 'lodash/omit';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import css from './SearchFiltersPrimary.module.css';

const SearchFiltersPrimaryComponent = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedSecondaryFiltersCount,
    secondaryFilters,
    urlQueryParams,
    intl,
    config,
    history,
  } = props;
  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, className);
  const selectedCategories = urlQueryParams['pub_category']?.split(',');
  const categoriesCount = selectedCategories?.length || 0;
  const hasSelectedCategories = categoriesCount > 0;
  const categoryFilterConfig =
    hasSelectedCategories && secondaryFilters?.find(f => f.id === 'category')?.config?.options;
  const selectedCategoriesLabels =
    hasSelectedCategories &&
    categoryFilterConfig
      ?.filter(f => selectedCategories?.includes(f.key))
      ?.map(f => f.label)
      ?.join(', ');
  const selectedTags = urlQueryParams['pub_tags']?.split(',');
  const tagsCount = selectedTags?.length || 0;
  const hasSelectedTags = tagsCount > 0;
  const tagsFilterConfig =
    hasSelectedTags && secondaryFilters?.find(f => f.id === 'tags')?.config?.options;
  const selectedTagsLabels =
    hasSelectedTags &&
    tagsFilterConfig
      ?.filter(f => selectedTags?.includes(f.key))
      ?.map(f => intl.formatMessage({ id: f.label }))
      ?.join(', ');
  const totalSelectedSecondaryFilters = categoriesCount + tagsCount;
  const toggleSecondaryFiltersOpenButtonClasses =
    isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;
  const toggleSecondaryFiltersOpenButton = toggleSecondaryFiltersOpen ? (
    <button
      className={toggleSecondaryFiltersOpenButtonClasses}
      onClick={() => {
        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
      }}
    >
      <span>
        <FormattedMessage
          id="SearchFiltersPrimary.moreFilters"
          values={{ count: totalSelectedSecondaryFilters }}
        />
        <FormattedMessage
          id="SearchFiltersPrimary.moreFiltersCategories"
          values={{ categoriesCount, categories: selectedCategoriesLabels }}
        />
        <FormattedMessage
          id="SearchFiltersPrimary.moreFiltersTags"
          values={{ tagsCount, tags: selectedTagsLabels }}
        />
      </span>
    </button>
  ) : null;

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#4a4a4a',
    fontSize: '16px',
    fontWeight: 'bold',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const colourStyles = {
    control: styles => ({ ...styles }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#e7e7e7' : '#ffffff',
        color: isFocused ? '#4a4a4a' : '#4a4a4a',
        fontWeight: 'normal',
      };
    },
    group: styles => ({ ...styles, borderBottom: '1px solid #AFAFAF' }),
    singleValue: styles => ({ ...styles, color: '#4A4A4F', fontWeight: 'normal' }),
    valueContainer: styles => ({ ...styles, padding: '7px 24px 7px 8px' }),
  };

  const formatGroupLabel = data => {
    return data.label ? (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    ) : null;
  };

  const schools = config.defaultSchoolsLocations || [];
  const findSchool = val => {
    const school = flatten(schools.map(s => s.schools)).find(s => s.id === val);
    return school;
  };
  const defaultSchool = findSchool(urlQueryParams?.school);
  const schoolDefaultValue = !!defaultSchool
    ? { option: defaultSchool.id, label: defaultSchool.name }
    : null;

  const createGroupOptions = options =>
    options?.map(o => {
      return {
        option: `${o.id}`,
        label: o.label,
        options: o?.schools.map(no => {
          return {
            option: no.id,
            label: no.name,
            isSelected: no.id === schoolDefaultValue?.option,
          };
        }),
      };
    });

  const schoolOptions = createGroupOptions(schools);

  const handleSchoolChange = selectedOption => {
    if (!!selectedOption?.option) {
      const selectedSchoolOption = findSchool(selectedOption.option);
      const { address, bounds, origin } = parse(selectedSchoolOption.search);
      let search = {
        ...urlQueryParams,
        address,
        bounds,
        origin,
        school: selectedSchoolOption.id,
      };
      search = omit(search, "sort")
      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
    } else {
      const defaultSearch = 'address=Oakland%2C%20California%2C%20United%20States&bounds=38.03502375%2C-121.99202837%2C37.46392173%2C-122.55380552&sort=pub_boost%2CcreatedAt';
      const { address, bounds, origin, sort } = parse(defaultSearch);
      let search = {
        ...urlQueryParams,
        address,
        bounds,
        origin,
        sort,
      };
      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
    }
  };

  const schoolPlaceholder = <FormattedMessage id="SearchFiltersPrimary.selectSchool" />;
  const schoolsSelect = (
    <Select
      // menuIsOpen={true}
      id={'schoolsSelect'}
      className={css.schoolsSelect}
      classNamePrefix="select"
      name={'schoolName'}
      onChange={handleSchoolChange}
      defaultValue={schoolDefaultValue}
      placeholder={schoolPlaceholder}
      options={schoolOptions}
      formatGroupLabel={formatGroupLabel}
      styles={colourStyles}
      isClearable={true}
    />
  );
  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        {listingsAreLoaded ? (
          <div className={css.searchResultSummary}>
            <span className={css.resultsFound}>
              <FormattedMessage
                id="SearchFiltersPrimary.foundResults"
                values={{ count: resultsCount }}
              />
            </span>
          </div>
        ) : null}
        {sortByComponent}
      </div>

      <div className={css.filterWrapper}>
        <div className={css.filters}>
          {children}
          {toggleSecondaryFiltersOpenButton}
        </div>
        <div className={css.schoolsFilter}>{schoolsSelect}</div>
      </div>

      {hasNoResult ? (
        <div className={css.noSearchResults}>
          <FormattedMessage id="SearchFiltersPrimary.noResults" />
        </div>
      ) : null}

      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFiltersPrimary.loadingResults" />
        </div>
      ) : null}
    </div>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
  sortByComponent: null,
};

SearchFiltersPrimaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
  sortByComponent: node,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default SearchFiltersPrimary;
