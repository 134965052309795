import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-oakland',
    predictionPlace: {
      address: 'Oakland, California, USA',
      bounds: new LatLngBounds(new LatLng(37.868356,-122.074183), new LatLng(37.631501851,-122.355071576)),
    },
  },
  {
    id: 'default-san-francisco',
    predictionPlace: {
      address: 'San francisco, california, usa',
      bounds: new LatLngBounds(new LatLng(37.866488313, -122.301070095), new LatLng(37.670768986, -122.544637459)),
    },
  },
];
export default defaultLocations;
