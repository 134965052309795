import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconInfo.module.css';

const IconInfo = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      viewBox="0 0 24 24"
      width="16"
      height="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" stroke="#1C274C" stroke-width="2" />
      <path d="M12 17V11" stroke="#1C274C" stroke-width="2" stroke-linecap="round" />
      <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#1C274C" />
    </svg>
  );
};

const { string } = PropTypes;

IconInfo.defaultProps = {
  className: null,
  rootClassName: null,
};

IconInfo.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconInfo;
