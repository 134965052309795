import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LinkTabNavHorizontal } from '../../components';
import { connect } from 'react-redux';

import css from './AdminNav.module.css';

const AdminNav = props => {
  const { className, rootClassName, selectedPageName, isAdmin, currentUser, params } = props;
  const classes = classNames(rootClassName || css.root, className);

  const tabs = [
  
    {
      text: <FormattedMessage id="AdminNav.listings" />,
      selected: params?.pageId === 'listings',
      disabled: false,
      linkProps: {
        name: 'AdminPanelPage',
        params: {pageId: 'listings'}
      },
    },
    {
      text: <FormattedMessage id="AdminNav.users" />,
      selected: params?.pageId === 'users',
      disabled: false,
      linkProps: {
        name: 'AdminPanelPage',
        params: {pageId: 'users'}
      },
    },
  ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state.user.isAdmin, // Update this based on your reducer
    currentUser: state.user.currentUser,
  };
};

AdminNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

AdminNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
};

export default connect(mapStateToProps)(AdminNav);
