import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import css from './AdminListingEdit.module.css';
import { openListing } from '../../containers/AdminPanelListingEditPage/AdminPanelListingEditPage.duck';
export function AdminListingEdit(props) {
    const { category, state, description, title, priceAmount, id,
        priceCurrency, geolocation, availabilityPlan, address,
        createdAt, streetNumber, streetName, unit, city, zipCode, handleChange,
        openListing
    } = props;

    const dispatch = useDispatch();
    function openListingHandle() {
        dispatch(openListing({ id }))
    }

    return (
        <div className={css.listing} >
            <button className={css.save_button} onClick={openListingHandle}>Open Listing</button>
            <form className={css.listing} onSubmit={handleChange}>


                <div className={css.lising__header} >
                    <h2>Title</h2>
                    <p className={css.lising__category} ><input name='title' type="text" defaultValue={title} /> </p>
                </div>
                <div className={css.lising__header} >
                    <h2>Street Number</h2>
                    <p className={css.lising__category} ><input name='streetNumber' type="text" defaultValue={streetNumber} /> </p>
                </div>

                <div className={css.lising__header} >
                    <h2>Street Name</h2>
                    <p className={css.lising__category} ><input name='streetName' type="text" defaultValue={streetName} /> </p>
                </div>

                <div className={css.lising__header} >
                    <h2>Unit</h2>
                    <p className={css.lising__category} ><input name='unit' type="text" defaultValue={unit} /> </p>
                </div>

                <div className={css.lising__header} >
                    <h2>City</h2>
                    <p className={css.lising__category} ><input name='city' type="text" defaultValue={city} /> </p>
                </div>

                <div className={css.lising__header} >
                    <h2>ZipCode</h2>
                    <p className={css.lising__category} ><input name='zipCode' type="text" defaultValue={zipCode} /> </p>
                </div>
                <div className={css.lising__header} >
                    <h2>Category</h2>
                    <p className={css.lising__category} ><input name='category' type="text" defaultValue={category} /> </p>
                </div>
                <h2>Price</h2>
                <div className={css.lising__header} >
                    <p className={css.lising__price} ><input name='priceAmount' type="number" defaultValue={priceAmount} /></p>
                </div>
                <h2>Description</h2>
                <div className={css.listing__body} >
                    <div className={css.listing__description} >
                        <p><textarea name="description" defaultValue={description}></textarea></p>
                    </div>
                </div>
                <h2>Geolocation</h2>
                <div className={css.listing__body} >
                    <div className={css.listing__geolocation} >
                        <p>{geolocation?.lat}/{geolocation?.lng}</p>
                    </div>
                </div>
                <h2>Availability Plan</h2>
                <div className={css.listing__body} >
                    <div className={css.listing__availibilityPlan} >
                        <p>{availabilityPlan?.type}</p>
                    </div>
                </div>
                <h2>Address</h2>
                <div className={css.listing__body} >
                    <div className={css.listing__address} >
                        <p>{address}</p>
                    </div>
                </div>

                <button className={css.save_button} >Save Changes</button>
                <h2>Footer</h2>
                <div className={css.listing__footer} >
                    <p className={css.listing__createdAt} >Created at: {createdAt}</p>
                    <p className={css.listing__state} >State: {state}</p>
                </div>

            </form>
        </div>
    )
}

