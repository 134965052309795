import React from 'react';
import { maxLength } from '../../util/validators';
import { FieldCheckboxGroup } from '../../components';

import css from './EditListingDescriptionForm.module.css';

const MAX_TAGS = 4;

const CustomTagsFieldMaybe = props => {
  const { name, id, tags, tagsLabelOverride, tagsPlaceholderOverride, intl } = props;
  const tagsLabel =  tagsLabelOverride || intl.formatMessage({
    id: 'EditListingDescriptionForm.tagsLabel',
  });

  const maxTagsMessage =  intl.formatMessage({
    id: 'EditListingDescriptionForm.maxTagsMessage',
  },
  {
    max: MAX_TAGS,
  });

  const maxTags = maxLength(maxTagsMessage, MAX_TAGS);

  return tags ? (
    <FieldCheckboxGroup
      className={css.tags}
      name={name}
      id={id}
      label={tagsLabel}
      options={tags}
      validate={maxTags}
    />
  ) : null;
};

export default CustomTagsFieldMaybe;
