import { FormattedMessage } from './util/reactIntl';
/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 5000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'category',
    label: 'Category',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'studios', label: 'Studios' },
        { key: 'junior1Bedroom', label: 'Junior 1-Bedroom' },
        { key: '1Bedrooms', label: '1-Bedrooms' },
        { key: '2Bedrooms', label: '2-Bedrooms' },
        { key: '3Bedrooms', label: '3-Bedrooms' },
        { key: '4Bedrooms', label: '4-Bedrooms' },
        { key: 'singleFamilyHomes', label: 'Single Family' },
      ],
    },
  },
  {
    id: 'tags',
    label: 'Badges/tags',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_tags'],
    config: {
      options: [
        { key: 'greatDeal', label: 'EditListingDescriptionForm.tags.greatDeal' },
        { key: 'niceAmenities', label: 'EditListingDescriptionForm.tags.niceAmenities' },
        { key: 'utilitiesIncluded', label: 'EditListingDescriptionForm.tags.utilitiesIncluded' },
        { key: 'incomeAndCreditFriendly', label: 'EditListingDescriptionForm.tags.incomeAndCreditFriendly' },
        { key: 'furnished', label: 'EditListingDescriptionForm.tags.furnished' },
        { key: 'refundedApplication', label: 'EditListingDescriptionForm.tags.refundedApplication' },
        { key: 'securityDepositProgram', label: 'EditListingDescriptionForm.tags.securityDepositProgram' },
        { key: 'petFriendly', label: 'EditListingDescriptionForm.tags.petFriendly' },
      ],
    },
  },
  // {
  //   id: 'status',
  //   label: 'Status',
  //   type: 'SelectSingleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_status'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'pipeline', label: 'Pipeline' },
  //       { key: 'available', label: 'Available' },
  //       { key: 'closed', label: 'Closed' },
  //       { key: 'leased', label: 'Leased' },
  //     ],
  //   },
  // }
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword', 'origin'],

  options: [
    { key: 'pub_boost,createdAt', label: 'Newest' },
    { key: 'pub_boost,-createdAt', label: 'Oldest' },
    { key: 'pub_boost,-price', label: 'Lowest price' },
    { key: 'pub_boost,price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance' },
  ],
};
