import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { pathByRouteName } from '../../util/routes';
import { Modal } from '../../components';

import EmailReminder from './EmailReminder';
import StripeAccountReminder from './StripeAccountReminder';
import MissingSignupDetails from './MissingSignupDetails/MissingSignupDetails';
import css from './ModalMissingInformation.module.css';
import isEmpty from 'lodash/isEmpty';

const MISSING_INFORMATION_MODAL_WHITELIST = [
  'LoginPage',
  'SignupPage',
  'ContactDetailsPage',
  'EmailVerificationPage',
  'PasswordResetPage',
  'StripePayoutPage',
  'PrivacyPolicyPage',
  'TermsOfServicePage',
];

const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
const STRIPE_ACCOUNT = 'STRIPE_ACCOUNT';
const SIGNUP_DETAILS = 'SIGNUP_DETAILS';
const USER_FIELDS = {
  required: [
    'phoneNumber',
    'workingAsTeacher',
    'classifiedToTeacherProgram',
    'startYearWithOUSD',
    'moving',
    'acceptTermsAndPolicy',
    'emailAgreement',
    'contactAgreement',
  ],
};

const singnupMissingFields = currentUser => {
  const profile = currentUser?.attributes?.profile || {};
  const {
    genderPronoun,
    otherGender,
    unitSize,
    neighborhoods,
    OUSDEmployerID,
    dontHaveEmployerID,
    householdAnnualIncome,
    householdAnnualIncomeRatherNotAnswer,
    creditScore,
    creditScoreRatherNotAnswer,
    hasSubmittedMissingSignupDetails,
  } = profile.protectedData || {};

  let missingFields = [];

  if (!OUSDEmployerID && !dontHaveEmployerID) {
    missingFields.push('OUSDEmployerID', 'dontHaveEmployerID');
  }
  if (!householdAnnualIncome && isEmpty(householdAnnualIncomeRatherNotAnswer)) {
    missingFields.push('householdAnnualIncome', 'householdAnnualIncomeRatherNotAnswer');
  }
  if (!creditScore && isEmpty(creditScoreRatherNotAnswer)) {
    missingFields.push('creditScore', 'creditScoreRatherNotAnswer');
  }

  const missingRequiredFields = USER_FIELDS.required.filter(f => {
    const fieldValue = profile?.protectedData[f];
    if (typeof fieldValue === 'object') {
      return isEmpty(fieldValue);
    } else {
      return !fieldValue;
    }
  });

  const isMissingRequiredFields = missingRequiredFields?.length > 0 || missingFields?.length > 0;
  // Show optional fields only if there are some missing required fields 
  if (isMissingRequiredFields && !hasSubmittedMissingSignupDetails && !genderPronoun && !otherGender) {
    missingFields.push('genderPronoun', 'otherGender');
  }
  if (isMissingRequiredFields && !hasSubmittedMissingSignupDetails && !unitSize) {
    missingFields.push('unitSize');
  }
  if (isMissingRequiredFields && !hasSubmittedMissingSignupDetails && !neighborhoods) {
    missingFields.push('neighborhoods');
  }

  const allMissingFields = [...missingRequiredFields, ...missingFields];
  return allMissingFields;
};
class ModalMissingInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMissingInformationReminder: null,
      hasSeenMissingInformationReminder: false,
    };
    this.handleMissingInformationReminder = this.handleMissingInformationReminder.bind(this);
    this.handleProfileUpdate = this.handleProfileUpdate.bind(this);
  }

  componentDidUpdate() {
    const { currentUser, currentUserHasListings, currentUserHasOrders, location } = this.props;
    const user = ensureCurrentUser(currentUser);
    this.handleMissingInformationReminder(
      user,
      currentUserHasListings,
      currentUserHasOrders,
      location
    );
  }

  handleMissingInformationReminder(
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    newLocation
  ) {
    const routes = routeConfiguration();
    const whitelistedPaths = MISSING_INFORMATION_MODAL_WHITELIST.map(page =>
      pathByRouteName(page, routes)
    );

    // Is the current page whitelisted?
    const isPageWhitelisted = whitelistedPaths.includes(newLocation.pathname);

    // Track if path changes inside Page level component
    const pathChanged = newLocation.pathname !== this.props.location.pathname;
    const notRemindedYet =
      !this.state.showMissingInformationReminder && !this.state.hasSeenMissingInformationReminder;

    // Is the reminder already shown on current page
    const showOnPathChange = notRemindedYet || pathChanged;

    if (!isPageWhitelisted && showOnPathChange) {
      // Emails are sent when order is initiated
      // Customer is likely to get email soon when she books something
      // Provider email should work - she should get an email when someone books a listing
      const hasOrders = currentUserHasOrders === true;
      const hasListingsOrOrders = currentUserHasListings || hasOrders;

      const emailUnverified = !!currentUser.id && !currentUser.attributes.emailVerified;
      const emailVerificationNeeded = hasListingsOrOrders && emailUnverified;

      const stripeAccountMissing = !!currentUser.id && !currentUser.attributes.stripeConnected;
      const stripeAccountNeeded = currentUserHasListings && stripeAccountMissing;
      
      // New fields were added in singnup form this logic is get missing fields data from existing users
      const isRoleUser = !!currentUser?.attributes?.profile?.protectedData?.isRoleUser;
      const userSingnupMissingFields = !!currentUser.id && !isRoleUser && singnupMissingFields(currentUser);
      const isSingnupDetailsMissing = userSingnupMissingFields?.length > 0;
      // Show reminder
      if (emailVerificationNeeded) {
        this.setState({ showMissingInformationReminder: EMAIL_VERIFICATION });
      } else if (isSingnupDetailsMissing) {
        this.setState({ showMissingInformationReminder: SIGNUP_DETAILS });
      }
      // else if (stripeAccountNeeded) {
      //   this.setState({ showMissingInformationReminder: STRIPE_ACCOUNT });
      // }
    }
  }

  handleProfileUpdate(values) {
    const params = { protectedData: { ...values, hasSubmittedMissingSignupDetails: true } };
    this.props.onUpdateProfile(params).then(user => {
      this.setState({ showMissingInformationReminder: null });
    });
  }

  render() {
    const {
      rootClassName,
      className,
      containerClassName,
      currentUser,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onManageDisableScrolling,
      onResendVerificationEmail,
      profileUpdateInProgress,
      intl,
    } = this.props;

    const user = ensureCurrentUser(currentUser);
    const classes = classNames(rootClassName || css.root, className);

    let content = null;

    const currentUserLoaded = user && user.id;
    if (currentUserLoaded) {
      if (this.state.showMissingInformationReminder === EMAIL_VERIFICATION) {
        content = (
          <EmailReminder
            className={classes}
            user={user}
            onResendVerificationEmail={onResendVerificationEmail}
            sendVerificationEmailInProgress={sendVerificationEmailInProgress}
            sendVerificationEmailError={sendVerificationEmailError}
          />
        );
      } else if (this.state.showMissingInformationReminder === SIGNUP_DETAILS) {
        const userSingnupMissingFields = singnupMissingFields(currentUser);
        content = (
          <MissingSignupDetails
            className={classes}
            onSubmit={this.handleProfileUpdate}
            inProgress={profileUpdateInProgress}
            user={user}
            userMissingFields={userSingnupMissingFields}
            intl={intl}
          />
        );
      } else if (this.state.showMissingInformationReminder === STRIPE_ACCOUNT) {
        content = <StripeAccountReminder className={classes} />;
      }
    }

    const closeButtonMessage = (
      <FormattedMessage id="ModalMissingInformation.closeVerifyEmailReminder" />
    );

    return (
      <Modal
        id="MissingInformationReminder"
        containerClassName={containerClassName}
        isOpen={!!this.state.showMissingInformationReminder}
        onClose={() => {
          this.state.showMissingInformationReminder == SIGNUP_DETAILS ? false : this.setState({
            showMissingInformationReminder: null,
            hasSeenMissingInformationReminder: true,
          });
        }}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
        hideCloseBtn={this.state.showMissingInformationReminder == SIGNUP_DETAILS}
      >
        {content}
      </Modal>
    );
  }
}

ModalMissingInformation.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
};

ModalMissingInformation.propTypes = {
  id: string.isRequired,
  className: string,
  rootClassName: string,
  containerClassName: string,

  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool.isRequired,
};

ModalMissingInformation.displayName = 'ModalMissingInformation';

export default ModalMissingInformation;
