import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { IconReviewUser, Modal } from '../../components';

import css from './SearchConfigModal.module.css';
import SortableList from '../SortableList/SortableList';
import { useDispatch } from 'react-redux';
import { updateHeadersComplete, updateHeaders } from '../../containers/AdminPanelPage/AdminPanelPage.duck';

const SearchConfigModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    searchconfigeeName,
    headers,
  } = props;

  const dispatch = useDispatch();

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'SearchConfigModal.later' });
  const searchconfigee = <span className={css.searchconfigee}>{searchconfigeeName}</span>;

  const onManageDisableScrolling = isOpen => {
    const { onManageDisableScrolling } = props;
    if (onManageDisableScrolling) {
      onManageDisableScrolling(isOpen);
    }
  };

  const onClose = () => {
    console.log('onCloseModal', onCloseModal)
    if (onCloseModal) {
      onCloseModal();
    }
  }

  const onToggleIsOn = (field) => {
    console.log('onToggleIsOn', field, headers[field].isOn)
    dispatch(updateHeaders({ field, isOn: !headers[field].isOn }));
}

const onMoveItem = (items) => {
    console.log('items', items)
    dispatch(updateHeadersComplete({ items }));
}

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      onManageDisableScrolling={onManageDisableScrolling}
      isOpen={isOpen}
      onClose={onClose}
      usePortal
      closeButtonMessage={closeButtonMessage}
      
    >
      <IconReviewUser className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="SearchConfigModal.title" values={{ searchconfigeeName: searchconfigee }} />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="SearchConfigModal.description" />
      </p>
      <SortableList data={headers} onToggleIsOn={onToggleIsOn} onMoveItem={onMoveItem} ></SortableList>
    </Modal>
  );
};

const { bool, string } = PropTypes;

SearchConfigModal.defaultProps = {
  className: null,
  rootClassName: null,
  searchconfigSent: false,
  sendSearchConfigInProgress: false,
  sendSearchConfigError: null,
};

SearchConfigModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  sendSearchConfigInProgress: bool,
  sendSearchConfigError: propTypes.error,
};

export default injectIntl(SearchConfigModal);
