import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Button, IconReviewUser, Modal } from '..';

import css from './ConfirmListingDeleteModal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { getIdToDelete } from '../../containers/AdminPanelPage/AdminPanelPage.duck';

const ConfirmListingDeleteModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    confirmlistingdeleteeeName,
    headers,
    onConfirmDelete,
  } = props;


  const dispatch = useDispatch();
  const idToDelete = useSelector(getIdToDelete)
  console.log('idToDelete', idToDelete)

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'ConfirmListingDeleteModal.later' });
  const confirmlistingdeleteee = <span className={css.confirmlistingdeleteee}>{confirmlistingdeleteeeName}</span>;

  const onManageDisableScrolling = isOpen => {
    const { onManageDisableScrolling } = props;
    if (onManageDisableScrolling) {
      onManageDisableScrolling(isOpen);
    }
  };

  const onClose = () => {
    console.log('onCloseModal', onCloseModal)
    if (onCloseModal) {
      onCloseModal();
    }
  }

  const onToggleIsOn = (field) => {
    console.log('onToggleIsOn', field, headers[field].isOn)
    dispatch(updateHeaders({ field, isOn: !headers[field].isOn }));
}

const onMoveItem = (items) => {
    console.log('items', items)
    dispatch(updateHeadersComplete({ items }));
}
 console.log('isOpen', isOpen)
  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      onManageDisableScrolling={onManageDisableScrolling}
      isOpen={isOpen}
      onClose={onClose}
      usePortal
      closeButtonMessage={closeButtonMessage}
      
    >
     {/* <IconReviewUser className={css.modalIcon} /> */}
      <p className={css.modalTitle}>
        <FormattedMessage id="ConfirmListingDeleteModal.title" values={{ confirmlistingdeleteeeName: confirmlistingdeleteee }} />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ConfirmListingDeleteModal.description" />: {idToDelete?.attributes.title || 'no id'}
      </p>
      <div className={css.modalButtons}>
      <Button onClick={() => props.onConfirmDelete(idToDelete)} className={css.confirmButton}>
          <FormattedMessage id="ConfirmListingDeleteModal.confirm" />
        </Button>
        <Button onClick={onClose} className={`${css.cancelButton}`}>
          <FormattedMessage id="ConfirmListingDeleteModal.cancel" />
        </Button>
      </div>
    </Modal>
  );
};

const { bool, string } = PropTypes;

ConfirmListingDeleteModal.defaultProps = {
  className: null,
  rootClassName: null,
  confirmlistingdeleteSent: false,
  sendConfirmListingDeleteInProgress: false,
  sendConfirmListingDeleteError: null,
};

ConfirmListingDeleteModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  sendConfirmListingDeleteInProgress: bool,
  sendConfirmListingDeleteError: propTypes.error,
};

export default injectIntl(ConfirmListingDeleteModal);
