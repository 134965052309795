
import { getAdminListing, patchAdminListing, patchAdminListingOpen } from '../../util/api';

// ================ Action types ================ //

export const FETCH_LISTING_REQUEST = 'app/AdminPanelListingEditPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTING_SUCCESS = 'app/AdminPanelListingEditPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTING_ERROR = 'app/AdminPanelListingEditPage/FETCH_LISTINGS_ERROR';

export const SET_LISTING_ERROR = 'app/AdminPanelListingEditPage/SET_LISTING_ERROR';

export const RESET_LISTING = 'app/AdminPanelListingEditPage/RESET_LISTING';


// ================ Reducer ================ //

const initialState = {
  fetchInProgress: true,
  fetchError: null,
  listing: {},
};

export default function adminPanelPageListingEditReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTING_REQUEST:
      return { ...state, fetchInProgress: true, fetchError: null };
    case FETCH_LISTING_SUCCESS: {
      const listing = payload.data.data[0];
      return {
        ...state, fetchInProgress: true, fetchError: null,
        listing: listing,
      };
    }
    case SET_LISTING_ERROR: {
      const error = payload;
      return {
        ...state, fetchInProgress: true, fetchError: error,
      };
    }
    case RESET_LISTING: {
      return initialState;
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchListingRequest = ({id}) => ({
  type: FETCH_LISTING_REQUEST,
  payload: { id}
});
export const fetchListingSuccess = response => ({
  type: FETCH_LISTING_SUCCESS,
  payload: response,
});
const fetchListingError = e => ({
  type: FETCH_LISTING_ERROR,
  error: true,
  payload: e,
});

export const resetListing = () => ({
  type: RESET_LISTING,
});


// ================ Thunks ================ //
export const loadData = ({ params }) => async (dispatch, getState, sdk) => {
  const { id } = params;
  dispatch(fetchListingRequest({ id }));
  try {
    const response = await getAdminListing({id});
    dispatch(fetchListingSuccess(response));
  } catch (e) {
    dispatch(fetchListingError(e));
  }
};

export const updateListing = ({ id, category, description, streetNumber, streetName, unit, city, zipCode, title }) => async (dispatch, getState, sdk) => {
 
  try {
    const response = await patchAdminListing({ id, category, description, streetNumber, streetName, unit, city, zipCode, title });
  } catch (e) {
    console.log('error', e)
  }
};

export const openListing = ({ id }) => async (dispatch, getState, sdk) => {
  try {
    const response = await patchAdminListingOpen({ id });
    console.log('response', response)
  } catch (e) {
    console.log('error', e)
  }
};

// ================ Selectors ================ //

export const getListingId = state => {
  return state.AdminPanelListingEditPage.listing?.id?.uuid;
}
export const getListingType = state => state.type;
export const getListingDescription = state => state.AdminPanelListingEditPage.listing.attributes?.description;
export const getListingGeolocation = state => state.AdminPanelListingEditPage.listing.attributes?.geolocation;
export const getListingDeletedStatus = state => state.AdminPanelListingEditPage.listing.attributes?.deleted;
export const getListingCreatedAt = state => state.AdminPanelListingEditPage.listing.attributes?.createdAt;
export const getListingState = state => state.AdminPanelListingEditPage.listing.attributes?.state;
export const getListingPrivateData = state => state.AdminPanelListingEditPage.listing.attributes?.privateData;
export const getListingTitle = state => state.AdminPanelListingEditPage.listing.attributes?.title;
export const getListingAvailabilityPlan = state => state.AdminPanelListingEditPage.listing?.attributes?.availabilityPlan;
export const getListingCategory = state => state.AdminPanelListingEditPage.listing.attributes?.publicData?.category;
export const getListingAddress = state => state.AdminPanelListingEditPage.listing.attributes?.publicData?.location.address;
export const getListingPriceAmount = state => state.AdminPanelListingEditPage.listing.attributes?.price?.amount;
export const getListingPriceCurrency = state => state.AdminPanelListingEditPage.listing.attributes?.price?.currency;
export const getListingMetadataExtId = state => state.AdminPanelListingEditPage.listing.attributes?.metadata?.extId;
export const getListingStreetNumber = state => state.AdminPanelListingEditPage.listing.attributes?.publicData?.streetNumber;
export const getListingStreetName = state => state.AdminPanelListingEditPage.listing.attributes?.publicData?.streetName;
export const getListingUnit = state => state.AdminPanelListingEditPage.listing.attributes?.publicData?.unit;
export const getListingCity = state => state.AdminPanelListingEditPage.listing.attributes?.publicData?.city;
export const getListingZipCode = state => state.AdminPanelListingEditPage.listing.attributes?.publicData?.zipCode;
