import React from "react";
import css from './AdminPanelPagePagination.module.css';
import { useDispatch, useSelector } from "react-redux";
import { getPageMeta, getUsersPageMeta } from "../AdminPanelPage.duck";
import { updatePageMeta } from "../AdminPanelPage.duck";

const Pagination = ({isUsersPage}) => {

  const dispatch = useDispatch();
  const pageMeta = isUsersPage ? useSelector(getUsersPageMeta) : useSelector(getPageMeta) ;
  const { page, perPage, totalItems, totalPages } = pageMeta;
  const handleClick = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      dispatch(updatePageMeta({ page, perPage, totalItems, totalPages, page: newPage }));
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const renderPageNumbers = getPageNumbers().map((pageNumber) => (
   (pageNumber === page - 9 || pageNumber === page + 9 ||  pageNumber > page - 4 && pageNumber < page + 4) ? (<li
      key={pageNumber}
      className={css.pageItem}
    >

   
      <button onClick={() => handleClick(pageNumber)} className={`${css.pageLink} ${page === pageNumber ? css.pageItemActive: ''} ` }>

      {pageNumber === page + 9 ? <span>... </span> : ''}
        {pageNumber} 
        {pageNumber === page - 9 ? <span> ... </span> : ''}
      </button>
    </li>) : ''
  ));

  return (
    <nav>
      <ul className={css.pagination}>
        <li className={css.pageItem}>
          <button
            onClick={() => handleClick(page - 1)}
            className={css.pageLink}
            aria-label="Previous"
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {renderPageNumbers}
        <li className={css.pageItem}>
          <button
            onClick={() => handleClick(page + 1)}
            className={css.pageLink}
            aria-label="Next"
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;