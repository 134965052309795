// BackToAdmin.js
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import css from './BackToAdminButton.module.css';
import Button from '../Button/Button';

const BackToAdmin = () => {
    const history = useHistory();
    const location = useLocation();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Check if the previous route was '/admin-panel'
        const checkLastRoute = () => {
            const previousRoute = history.location;
            if (previousRoute !== '/admin-panel/listings') {
                setIsVisible(true);
            }
        };

        checkLastRoute();
    }, [history]);

    const handleClick = () => {
        history.push('/admin-panel/listings');
    };

    // If the component is not visible, don't render anything
    if (!isVisible) {
        return null;
    }

    return (
        <div  className={css.submitButtonContainer}>
           <Button onClick={handleClick} className={css.submitButton}>
                Back to Admin Panel
            </Button>
        </div>
    );
};

export default BackToAdmin;
