import React, { useState } from 'react';
import { DragDropContextProvider as DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import SortableItem from './SortableItem';

const SortableList = props => {
  const { data } = props;
  // const [items, setItems] = useState(
  //   Object.entries(data).map(([key, value]) => ({ id: key, ...value })),
  // );
  const items = Object.entries(data).map(([key, value]) => ({ id: key, ...value }));
  const moveItem = (fromIndex, toIndex) => {
    const newItemsArray = [...items];
    const item = newItemsArray.splice(fromIndex, 1)[0];
    newItemsArray.splice(toIndex, 0, item);
    newItemsArray.forEach((item, index) => (item.order = index));

    const changedItems = newItemsArray.filter(newItem => {
      const oldItem = props.data[newItem.field];
      return oldItem.order !== newItem.order;
    });

    const changedItemsObject = changedItems.reduce((acc, item) => {
      acc[item.field] = item;
      return acc;
    }, {});
    // setItems(newItemsArray);
    props.onMoveItem(changedItemsObject);
  };

  const toggleIsOn = id => {
    // setItems((prevItems) =>
    //   prevItems.map((item) =>
    //     item.id === id ? { ...item, isOn: !item.isOn } : item,
    //   ),
    // );
    props.onToggleIsOn(id, items);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        {items
          .sort((a, b) => {
            if (a.order < b.order) {
              return -1;
            }
            if (a.order > b.order) {
              return 1;
            }
            return 0;
          })
          .map((item, index) => (
            <SortableItem
              key={item.id}
              id={item.id}
              data={item}
              index={index}
              moveItem={moveItem}
              toggleIsOn={toggleIsOn}
            />
          ))}
      </div>
    </DndProvider>
  );
};

export default SortableList;
