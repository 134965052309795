import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from './debounce.hook';
import css from './AdminPageSearchPanelComponent.module.css';
import {
  updateHeaders,
  updateSearchValue,
  updateCategory,
  updatePerPage,
  getPerPage,
  getHeaders,
  updateShowAll,
  updateStatus,
  updateHeadersComplete,
  getIsSearchConfigModalOpen,
  updateIsSearchConfigModalOpen,
  clearSearchParams,
  getShowAll,
  getIsConfirmListingDeleteModalOpen,
  updateIsConfirmListingDeleteModalOpen,
  removeListing,
  updateListingsTransactionsCount,
} from '../AdminPanelPage.duck';
import SortableList from '../../../components/SortableList/SortableList';
import SearchConfigModal from '../../../components/SearchConfigModal/SearchConfigModal';
import ConfirmListingDeleteModal from '../../../components/ConfirmListingDeleteModal/ConfirmListingDeleteModal';
import { Button, PrimaryButton } from '../../../components';
import DownloadCSVButton from '../../../components/DownloadInventory/DownloadInventoryButton';
import { postAdminListingDelete } from '../../../util/api';
export default function AdminPanelSearchPanelComponent(props) {
  const [searchValue, setSearchValue] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const dispatch = useDispatch();
  const perPage = useSelector(getPerPage);
  const headers = useSelector(getHeaders);
  const showAll = useSelector(getShowAll);
  const isSearchConfigModalOpen = useSelector(getIsSearchConfigModalOpen);
  const isConfirmListingDeleteModalOpen = useSelector(getIsConfirmListingDeleteModalOpen);
  const { data = [], isUsersPage, updateListingsTransactionsCountInProgress, updateListingsTransactionsCountSuccess, updateListingsTransactionsCountError, intl } = props;
  useEffect(() => {
    dispatch(updateSearchValue(debouncedSearchTerm));
  }, [debouncedSearchTerm]);
  const handleSearchChange = e => {
    setSearchValue(e.target.value);
  };

  const handleCategoryChange = e => {
    dispatch(updateCategory(e.target.value));
  };

  const handleStatusChange = e => {
    dispatch(updateStatus(e.target.value));
  };
  const checkForm = e => {
    dispatch(updateHeaders({ field: e.target.name, isOn: e.target.checked }));
  };

  const handlePerPageChange = e => {
    e.preventDefault();
    // get input name and value
    const { name, value, checked } = e.target;
    dispatch(updatePerPage(Number(e.target.value)));
  };

  const handleShowAllChange = e => {
    // get input name and value
    const { checked } = e.target;
    dispatch(updateShowAll(checked));
  };

  const onToggleIsOn = field => {
    dispatch(updateHeaders({ field, isOn: !headers[field].isOn }));
  };

  const onMoveItem = items => {
    dispatch(updateHeadersComplete({ items }));
  };
  const onClose = () => {
    dispatch(updateIsSearchConfigModalOpen(false));
  };
  const onOpen = () => {
    dispatch(updateIsSearchConfigModalOpen(true));
  };

  const onCloseConfirm = () => {
    dispatch(updateIsConfirmListingDeleteModalOpen(false));
  };

  const onReset = () => {
    dispatch(clearSearchParams());
  };

  const onUpdateListingsTransactionsCount = () => {
    dispatch(updateListingsTransactionsCount());
  }

  const onConfirmDelete = async listing => {
    const id = listing.id.uuid;
    await postAdminListingDelete({ id, publicData: { deleted: true } });
    dispatch(updateIsConfirmListingDeleteModalOpen(false));
    dispatch(removeListing(id));
  };
  return (
    <>
      {!isUsersPage ? (
        <div className={css.adminPanelSearchSection}>
          <label className={css.adminPanelSearchSectionLabel} htmlFor="search-bar">
            Search:
          </label>
          <input
            onInput={handleSearchChange}
            className={css.adminPanelSearchSectionInput}
            type="text"
            id="search-bar"
            name="search-bar"
            placeholder="Search by description, name or owner"
          />
        </div>
      ) : null}
      <div className={css.flex}>
        <form className={css.adminPanelHeaderVisibilty}>
          <label>
            <span className={css.perPageLabelText}>Show All:</span>
            <input
              onChange={handleShowAllChange}
              type="checkbox"
              name="showAll"
              checked={showAll}
              className={css.adminPanelSearchSectionNumberInput}
            />
          </label>
        </form>
        <form>
          <label className={css.perPageLabel}>
            <span className={css.perPageLabelText}>PerPage:</span>
            <input
              min="1"
              max="100"
              onKeyPress={e => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={handlePerPageChange}
              type="number"
              name="perPage"
              defaultValue={perPage}
              className={css.adminPanelSearchSectionNumberInput}
            />
          </label>
        </form>
        {!isUsersPage ? (
          <>
            <div className={css.adminPanelSearchSection}>
              <select
                defaultValue={props.status}
                onChange={handleStatusChange}
                className={css.adminPanelSearchSectionSelect}
                id="status-filter"
                name="status-filter"
              >
                <option value="">All Statuses</option>
                <option value="pipeline">Pipeline</option>
                <option value="available">Available</option>
                <option value="closed">Closed</option>
                <option value="leased">Leased</option>
              </select>
            </div>
            <div className={css.adminPanelSearchSection}>
              <select
                defaultValue={props.category}
                onChange={handleCategoryChange}
                className={css.adminPanelSearchSectionSelect}
                id="status-filter"
                name="status-filter"
              >
                <option value="">All Unit Types</option>
                <option value="studios">Studios</option>
                <option value="1Bedrooms">1-Bedrooms</option>
                <option value="2Bedrooms"> 2-Bedrooms</option>
                <option value="3Bedrooms">3-Bedrooms</option>
                <option value="singleFamilyHomes">singleFamilyHomes</option>
              </select>
            </div>
          </>
        ) : null}

        <button className={css.adminPanelSearchSectionSearchButton} onClick={onOpen}>
          <span className={css.adminPanelSearchSectionButtonText}>⚙</span>
        </button>
        {!isUsersPage ? (
          <button className={css.adminPanelSearchSectionSearchResetButton} onClick={onReset}>
            <span className={css.adminPanelSearchSectionButtonText}>Reset</span>
          </button>
        ) : null}
        <DownloadCSVButton data={data} headers={headers} />
        <div className={css.transactionsButtonWrapper}>
          <PrimaryButton inProgress={updateListingsTransactionsCountInProgress} ready={updateListingsTransactionsCountSuccess} className={css.transactionsButton} onClick={onUpdateListingsTransactionsCount}>
            <span className={css.adminPanelSearchSectionButtonText}>{intl.formatMessage({
              id: 'AdminPanelPage.updateListingsTransactionsCountBtnMessage',
            })}</span>
          </PrimaryButton>
          {
            !!updateListingsTransactionsCountError ? <span className={css.transactionsError}>{intl.formatMessage({
              id: 'AdminPanelPage.updateListingsTransactionsCountError',
            })}</span> : null
          }
        </div>
      </div>

      <SearchConfigModal
        headers={headers}
        id="SearchConfig"
        onCloseModal={onClose}
        onToggleIsOn={onToggleIsOn}
        isOpen={isSearchConfigModalOpen}
      ></SearchConfigModal>
      <ConfirmListingDeleteModal
        id="ConfirmListingDeleteModal"
        onCloseModal={onCloseConfirm}
        onConfirmDelete={onConfirmDelete}
        isOpen={isConfirmListingDeleteModalOpen}
      ></ConfirmListingDeleteModal>
      {/* <form className={css.adminPanelHeaderVisibilty} onChange={checkForm}>
            
            {Object.keys(headers).sort((a, b) => { return headers[a].order - headers[b].order}).map((key) => {
                return <label key={key}><input type="checkbox" name={headers[key].field} defaultChecked={headers[key].isOn} /> {headers[key].label}</label>                 
            })}
        </form> */}
    </>
  );
}
