import React from 'react';
import { injectIntl } from '../../util/reactIntl';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { getListingStreetName, getListingStreetNumber, getListingUnit, getListingZipCode,getListingCity, loadData, updateListing, resetListing } from './AdminPanelListingEditPage.duck';
import { useEffect } from 'react';

import {
  getListingId,
  getListingType,
  getListingDescription,
  getListingGeolocation,
  getListingDeletedStatus,
  getListingCreatedAt,
  getListingState,
  getListingPrivateData,
  getListingTitle,
  getListingAvailabilityPlan,
  getListingCategory,
  getListingAddress,
  getListingPriceAmount,
  getListingPriceCurrency,
  getListingMetadataExtId,
  openListing,
} from './AdminPanelListingEditPage.duck';
import { AdminListingEdit } from '../../components/AdminListingEdit/AdminListingEdit';


const mapStateToProps = state => ({
  id: getListingId(state),
  type: getListingType(state),
  description: getListingDescription(state),
  geolocation: getListingGeolocation(state),
  deletedStatus: getListingDeletedStatus(state),
  createdAt: getListingCreatedAt(state),
  state: getListingState(state),
  privateData: getListingPrivateData(state),
  title: getListingTitle(state),
  availabilityPlan: getListingAvailabilityPlan(state),
  category: getListingCategory(state),
  address: getListingAddress(state),
  priceAmount: getListingPriceAmount(state),
  priceCurrency: getListingPriceCurrency(state),
  metadataExtId: getListingMetadataExtId(state),
  streetNumber: getListingStreetNumber(state),
  streetName: getListingStreetName(state),
  unit: getListingUnit(state),
  zipCode: getListingZipCode(state),
  city: getListingCity(state),
});

const mapDispatchToProps = { 
  loadData,
  updateListing,
  openListing,
 };

export const AdminPanelListingEditComponent = ({ category, state, privateData, loadData, params, id, description, title, priceAmount, priceCurrency, geolocation, availabilityPlan, address, createdAt, streetNumber, streetName, unit, city, zipCode}) => {
  useEffect(() => {
    loadData({ params }); // Pass the params object to the loadData function
    return () => {
      dispatch(resetListing())
    };
  }, [loadData, params]);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.preventDefault();
    const form = event.target;
    dispatch(updateListing({ id,
        description: form.description.value,
        category: form.category.value,
        streetNumber: form.streetNumber.value,
        streetName: form.streetName.value,
        unit: form.unit.value,
        zipCode: form.zipCode.value,
        city: form.city.value,
        title: form.title.value,
       }));
  };

  return title ? (
    <AdminListingEdit 
    category={category} state={state}  id={id} description={description} title={title} priceAmount={priceAmount} priceCurrency={priceCurrency} geolocation={geolocation} availabilityPlan={availabilityPlan} address={address} createdAt={createdAt} streetNumber={streetNumber} streetName={streetName} unit={unit} city={city} zipCode={zipCode} handleChange={handleChange}   openListing={openListing} 
    />
  ) : '';
};


const AdminPanelListingEditPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl,
)(AdminPanelListingEditComponent);

export default AdminPanelListingEditPage;

