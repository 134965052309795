import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, isAuthenticated } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  const signupLink = (
    <NamedLink
      name="SignupPage"
      className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
    >
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = (
    <NamedLink
      name="LoginPage"
      className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
    >
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        {isAuthenticated ? (
          <NamedLink
            name="SearchPage"
            to={{
              search:
                'address=Oakland%2C%20California%2C%20United%20States&bounds=38.03502375%2C-121.99202837%2C37.46392173%2C-122.55380552&sort=pub_boost%2CcreatedAt',
              // '',
            }}
            className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
          >
            <FormattedMessage id="SectionHero.browseButton" />
          </NamedLink>
        ) : (
          <div className={css.authBtns}>
            {loginLink}
            {signupLink}
          </div>
        )}
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
