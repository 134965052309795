import React from 'react';
import css from './DownloadInventoryButton.module.css';
const DownloadCSVButton = ({ data, headers, fileName }) => {
  const getNestedValue = (obj, path) => {
    const keys = path.split('.');
    let current = obj;
    for (const key of keys) {
      if (current?.[key] === undefined) {
        return undefined;
      }
      current = current?.[key];
    }
    return current;
  };

  const arrayToCSV = (data, headers) => {
    const enabledHeaders = Object.values(headers).filter(header => header.isOn);
    const headerRow = '#,' + enabledHeaders.map(header => `"${header.label}"`).join(',');
  
    const rows = data.map((row, index) => {
      return `${index + 1},` + enabledHeaders
        .map(header => {
          const value = getNestedValue(row, header.path);
          return value !== undefined && value !== null
            ? `"${value.toString().replace(/"/g, '""')}"` 
            : '""';
        })
        .join(',');
    }).join('\n');
  
    return `${headerRow}\n${rows}`;
  };

  const downloadCSV = (data, headers, fileName = 'data.csv') => {
    const csvData = arrayToCSV(data, headers);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleDownload = () => {
    downloadCSV(data, headers, fileName);
  };

  return (
    <button className={css.adminPanelSearchSectionSearchResetButton} onClick={handleDownload}>
      Download CSV
    </button>
  );
};

export default DownloadCSVButton;
