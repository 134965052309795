import React, { useState } from 'react';
import css from './AdminPanelPageListingComponent.module.css';
import { isOn } from '../AdminPanelPage.helper';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getIsConfirmListingDeleteModalOpen,
  getSearchValue,
  updateIdToDelete,
  updateIsConfirmListingDeleteModalOpen,
  updateAdminListing,
} from '../AdminPanelPage.duck';
import {
  postAdminListingDelete,
  postAdminListingPublic,
  postAdminTransferOwnership,
} from '../../../util/api';
import {
  IconCheckmark,
} from '../../../components'
import ConfirmListingDeleteModal from '../../../components/ConfirmListingDeleteModal/ConfirmListingDeleteModal';
import { createSlug } from '../../../util/urlHelpers';

function formatListingTitle(title) {
  // Remove special characters, like parentheses and the hash symbol
  const cleaned = title.replace(/[\(\)#]/g, '');

  // Remove hidden Unicode character (Zero Width Space)
  const noHiddenChars = cleaned.replace(/\u200B/g, '');

  // Replace spaces, commas, and colons with hyphens
  const hyphenated = noHiddenChars.replace(/[ ,:]/g, '-');

  // Replace multiple consecutive hyphens with a single hyphen
  const normalized = hyphenated.replace(/-{2,}/g, '-');

  // Convert the string to lowercase
  const formatted = normalized.toLowerCase();

  return formatted;
}

const AdminPanelPageListingComponent = ({ listing, headers, intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedAction, setSelectedAction] = useState('no selection');
  const searchValue = useSelector(getSearchValue);
  const isConfirmListingDeleteModalOpen = useSelector(getIsConfirmListingDeleteModalOpen);

  const onClose = () => {
    dispatch(updateIsConfirmListingDeleteModalOpen(false));
  };
  const onOpen = id => {
    console.log('open');
    dispatch(updateIsConfirmListingDeleteModalOpen(true));
    dispatch(updateIdToDelete(id));
  };

  function updateSingleStatus(listing, value) {
    const payload = {
      id: listing.id.uuid,
      publicData: {
        status: value,
      },
    };
    postAdminListingPublic(payload);
  }

  function getValueFromListing(obj, field, list) {
    if (!obj) {
      return '';
    }

    if (list.length === 0) {
      return obj[field];
    }
    const nextField = list.shift();
    return getValueFromListing(obj[field], nextField, list);
  }

  function highlightTerm(listing, textLocation, term, convertToCurrency, key) {
    const formatting_options = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    };

    const pathToProperty = textLocation.split('.');
    const firstField = pathToProperty.shift();
    let text = getValueFromListing(listing, firstField, pathToProperty);
    if (key === 'address' && text) {
      text = text.replace(/, California/, '');
      text = text.replace(/, United States/, '');
      if (listing?.attributes?.publicData?.unit) {
        text = `${text}, #${listing?.attributes?.publicData?.unit}`;
      }
    }
    if (key.includes('Date') && text) {
      text = text.replace(/^(Mon|Tue|Wed|Thu|Fri|Sat|Sun)\s/, '');
    }
    if (key === 'status') {
      text = (
        <select
          defaultValue={text}
          className={css.adminPanelSearchSectionSelect}
          onChange={event => updateSingleStatus(listing, event.target.value)}
        >
          <option value="">...{isConfirmListingDeleteModalOpen}</option>
          <option value="pipeline">Pipeline</option>
          <option value="available">Available</option>
          <option value="closed">Closed</option>
          <option value="leased">Leased</option>
        </select>
      );
    }

    if (text && convertToCurrency) {
      text = Number(text.toString().slice(0, -2)).toLocaleString('en-US', formatting_options);
    }
    if (term && text) {
      if (typeof text !== 'string') {
        return text;
      }
      if (!text.toLowerCase().includes(term.toLowerCase())) {
        return truncateText(text);
      }
      const startIndex = text.toLowerCase().indexOf(term.toLowerCase());
      const endIndex = startIndex + term.length;
      const startText = text.slice(0, startIndex);
      const endText = text.slice(endIndex);
      const termText = text.slice(startIndex, endIndex);
      const wrappedText = (
        <span>
          {truncateText(startText, 20, true)}
          <span className={css.highlight}>{termText}</span>
          {truncateText(endText, 20)}
        </span>
      );
      return wrappedText;
    }
    return truncateText(text);
  }

  function truncateText(text, length = 40, start = false) {
    if (!text) {
      return '';
    }
    const startIndex = 0;
    if (text.length > length) {
      return start
        ? '...' + text.slice(startIndex, length)
        : text.slice(startIndex, length) + '...';
    }
    return text;
  }

  const onClickEdit = async e => {
    const slug = createSlug(listing.attributes.title);
    history.push(
      `/l/${slug}/${listing.id.uuid}/edit/description`
    );
  };

  const onClickView = async e => {
    const slug = createSlug(listing.attributes.title);
    history.push(`/l/${slug}/${listing.id.uuid}`);
  };
  const formatting_options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  };

  const onBoost = id => {
    const isBoosted = id?.attributes?.publicData?.boost === 1;
    const boostParams = isBoosted ? {boost: 0} : {boost: 1};
    const payload = {
      payload: {
        bodyParams: {
          id: id.id.uuid,
          publicData: boostParams,
        },
        queryParams: {
          expand: true,
        }
      },
    };
    dispatch(updateAdminListing(payload));
  };

  const onActionChange = async (e, id) => {
    const action = e.target.value;
    console.log('action', action, 'id', id);
    switch (action) {
      case 'edit':
        return onClickEdit();
      case 'view':
        return onClickView();
      case 'boost':
        e.target.value = '';
        return onBoost(id);
      case 'delete':
        e.target.value = '';
        return onOpen(id);
      default:
        return console.log('no action selected');
    }
  };

  return (
    <tr>
      {Object.keys(headers)
        .sort((a, b) => {
          return headers[a].order - headers[b].order;
        })
        .map(key => {
          const header = headers[key];
          if (header.isOn) {
            return (
              <td key={key}>
                {highlightTerm(listing, header.path, searchValue, header.convertToCurrency, key)}
              </td>
            );
          }
        })}
      <td>
        {/* select */}
        <select
          className={css.adminPanelSearchSectionSelect}
          id="action-selector"
          name="action-selector"
          onChange={e => onActionChange(e, listing)}
        >
          <option value="">...</option>
          <option value="edit" onClick={onClickEdit}>
            Edit
          </option>
          <option value="view" onClick={onClickView}>
            View
          </option>
          <option value="boost">
            { listing.attributes.publicData.boost === 1 ? intl.formatMessage({
              id: 'AdminPanelPageListingComponent.boosted',
            }) : intl.formatMessage({
              id: 'AdminPanelPageListingComponent.boost',
            }) }
          </option>
          <option value="delete">Delete</option>
        </select>
      </td>
    </tr>
  );
};

export default AdminPanelPageListingComponent;
