import React from 'react';
import css from './AdminPanelPageUserComponent.module.css';
import { useSelector } from 'react-redux';
import { getSearchValue } from '../AdminPanelPage.duck';

const AdminPanelPageUserComponent = ({ user, headers }) => {
  const searchValue = useSelector(getSearchValue);
  function getValueFromUser(obj, field, list) {
    if (!obj) {
      return '';
    }

    if (list.length === 0) {
      return obj[field];
    }
    const nextField = list.shift();
    return getValueFromUser(obj[field], nextField, list);
  }

  function highlightTerm(user, textLocation, term, convertToCurrency, key) {
    const formatting_options = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    };

    const pathToProperty = textLocation.split('.');
    const firstField = pathToProperty.shift();
    let text = getValueFromUser(user, firstField, pathToProperty);
    if (typeof text === 'boolean') {
      return text === true ? 'Yes' : 'No';
    }
    if (text && convertToCurrency) {
      text = Number(text.toString().slice(0, -2)).toLocaleString('en-US', formatting_options);
    }
    if (term && text) {
      if (typeof text !== 'string') {
        return text;
      }
      if (!text.toLowerCase().includes(term.toLowerCase())) {
        return truncateText(text);
      }
      const startIndex = text.toLowerCase().indexOf(term.toLowerCase());
      const endIndex = startIndex + term.length;
      const startText = text.slice(0, startIndex);
      const endText = text.slice(endIndex);
      const termText = text.slice(startIndex, endIndex);
      const wrappedText = (
        <span>
          {truncateText(startText, 20, true)}
          <span className={css.highlight}>{termText}</span>
          {truncateText(endText, 20)}
        </span>
      );
      return wrappedText;
    }
    return truncateText(text);
  }

  function truncateText(text, length = 40, start = false) {
    if (!text) {
      return '';
    }
    const startIndex = 0;
    if (text.length > length) {
      return start
        ? '...' + text.slice(startIndex, length)
        : text.slice(startIndex, length) + '...';
    }
    return text;
  }

  return (
    <tr>
      {Object.keys(headers)
        .sort((a, b) => {
          return headers[a].order - headers[b].order;
        })
        .map(key => {
          const header = headers[key];
          if (header.isOn) {
            return (
              <td key={key}>
                {highlightTerm(user, header.path, searchValue, header.convertToCurrency, key)}
              </td>
            );
          }
        })}
    </tr>
  );
};

export default AdminPanelPageUserComponent;
