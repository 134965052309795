import React, { Component } from 'react';
import { bool, func, object, node, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';

import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { ModalInMobile, Button } from '../../components';
import { parse } from '../../util/urlHelpers';
import Select from 'react-select';
import flatten from 'lodash/flatten';
import { pickSearchParamsOnly } from '../../containers/SearchPage/SearchPage.helpers';
import omit from 'lodash/omit';
import css from './SearchFiltersMobile.module.css';

class SearchFiltersMobileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isFiltersOpenOnMobile: false, initialQueryParams: null };

    this.openFilters = this.openFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
  }

  // Open filters modal, set the initial parameters to current ones
  openFilters() {
    const { onOpenModal, urlQueryParams } = this.props;
    onOpenModal();
    this.setState({ isFiltersOpenOnMobile: true, initialQueryParams: urlQueryParams });
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { history, onCloseModal } = this.props;

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        this.state.initialQueryParams
      )
    );
    onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false, initialQueryParams: null });
  }

  // Close the filter modal
  closeFilters() {
    this.props.onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false });
  }

  // Reset all filter query parameters
  resetAll(e) {
    this.props.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      rootClassName,
      className,
      children,
      sortByComponent,
      listingsAreLoaded,
      resultsCount,
      searchInProgress,
      showAsModalMaxWidth,
      onMapIconClick,
      onManageDisableScrolling,
      selectedFiltersCount,
      intl,
      config,
      urlQueryParams,
      history,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const resultsFound = (
      <FormattedMessage id="SearchFiltersMobile.foundResults" values={{ count: resultsCount }} />
    );
    const noResults = <FormattedMessage id="SearchFiltersMobile.noResults" />;
    const loadingResults = <FormattedMessage id="SearchFiltersMobile.loadingResults" />;
    const filtersHeading = intl.formatMessage({ id: 'SearchFiltersMobile.heading' });
    const modalCloseButtonMessage = intl.formatMessage({ id: 'SearchFiltersMobile.cancel' });

    const showListingsLabel = intl.formatMessage(
      { id: 'SearchFiltersMobile.showListings' },
      { count: resultsCount }
    );

    const filtersButtonClasses =
      selectedFiltersCount > 0 ? css.filtersButtonSelected : css.filtersButton;

    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: '#4a4a4a',
      fontSize: '16px',
      fontWeight: 'bold',
    };
    const groupBadgeStyles = {
      backgroundColor: '#EBECF0',
      borderRadius: '2em',
      color: '#172B4D',
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: '1',
      minWidth: 1,
      padding: '0.16666666666667em 0.5em',
      textAlign: 'center',
    };

    const colourStyles = {
      control: styles => ({ ...styles }),
      option: (styles, { isFocused }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? '#e7e7e7' : '#ffffff',
          color: isFocused ? '#4a4a4a' : '#4a4a4a',
          fontWeight: 'normal',
        };
      },
      group: styles => ({ ...styles, borderBottom: '1px solid #AFAFAF' }),
      singleValue: styles => ({ ...styles, color: '#4A4A4F', fontWeight: 'normal' }),
      valueContainer: styles => ({ ...styles, padding: '7px 24px 7px 8px' }),
    };

    const formatGroupLabel = data => {
      return data.label ? (
        <div style={groupStyles}>
          <span>{data.label}</span>
          <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
      ) : null;
    };

    const schools = config.defaultSchoolsLocations || [];
    const findSchool = val => {
      const school = flatten(schools.map(s => s.schools)).find(s => s.id === val);
      return school;
    };
    const defaultSchool = findSchool(urlQueryParams?.school);
    const schoolDefaultValue = !!defaultSchool
      ? { option: defaultSchool.id, label: defaultSchool.name }
      : null;
    const createGroupOptions = options =>
      options?.map(o => {
        return {
          option: `${o.id}`,
          label: o.label,
          options: o?.schools.map(no => {
            return {
              option: no.id,
              label: no.name,
              isSelected: no.id === schoolDefaultValue?.option,
            };
          }),
        };
      });

    const schoolOptions = createGroupOptions(schools);

    const handleSchoolChange = selectedOption => {
      if (!!selectedOption?.option) {
        const selectedSchoolOption = findSchool(selectedOption.option);
        const { address, bounds, origin } = parse(selectedSchoolOption.search);
        let search = {
          ...urlQueryParams,
          address,
          bounds,
          origin,
          school: selectedSchoolOption.id,
        };
        search = omit(search, "sort")
        history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
      } else {
        const defaultSearch = 'address=Oakland%2C%20California%2C%20United%20States&bounds=38.03502375%2C-121.99202837%2C37.46392173%2C-122.55380552&sort=pub_boost%2CcreatedAt';
        const { address, bounds, origin, sort } = parse(defaultSearch);
        let search = {
          ...urlQueryParams,
          address,
          bounds,
          origin,
          sort,
        };
        history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
      }
    };
    const schoolPlaceholder = <FormattedMessage id="SearchFiltersPrimary.selectSchool" />;
    const schoolsSelect = (
      <Select
        // menuIsOpen={true}
        id={'schoolsSelect'}
        className={css.schoolsSelect}
        classNamePrefix="select"
        name={'schoolName'}
        onChange={handleSchoolChange}
        defaultValue={schoolDefaultValue}
        placeholder={schoolPlaceholder}
        options={schoolOptions}
        formatGroupLabel={formatGroupLabel}
        styles={colourStyles}
        isClearable={true}
      />
    );

    return (
      <div className={classes}>
        <div className={css.searchResultSummary}>
          {listingsAreLoaded && resultsCount > 0 ? resultsFound : null}
          {listingsAreLoaded && resultsCount === 0 ? noResults : null}
          {searchInProgress ? loadingResults : null}
        </div>
        <div className={css.buttons}>
          <Button rootClassName={filtersButtonClasses} onClick={this.openFilters}>
            <FormattedMessage
              id="SearchFiltersMobile.filtersButtonLabel"
              className={css.mapIconText}
            />
          </Button>
          {sortByComponent}
          <div className={css.mapIcon} onClick={onMapIconClick}>
            <FormattedMessage id="SearchFiltersMobile.openMapView" className={css.mapIconText} />
          </div>
        </div>
        <div className={css.schoolsFilter}>{schoolsSelect}</div>
        <ModalInMobile
          id="SearchFiltersMobile.filters"
          isModalOpenOnMobile={this.state.isFiltersOpenOnMobile}
          onClose={this.cancelFilters}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          closeButtonMessage={modalCloseButtonMessage}
        >
          <div className={css.modalHeadingWrapper}>
            <span className={css.modalHeading}>{filtersHeading}</span>
            <button className={css.resetAllButton} onClick={e => this.resetAll(e)}>
              <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
            </button>
          </div>
          {this.state.isFiltersOpenOnMobile ? (
            <div className={css.filtersWrapper}>{children}</div>
          ) : null}

          <div className={css.showListingsContainer}>
            <Button className={css.showListingsButton} onClick={this.closeFilters}>
              {showListingsLabel}
            </Button>
          </div>
        </ModalInMobile>
      </div>
    );
  }
}

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  sortByComponent: null,
  resultsCount: null,
  searchInProgress: false,
  selectedFiltersCount: 0,
};

SearchFiltersMobileComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  sortByComponent: node,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onMapIconClick: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  resetAll: func.isRequired,
  selectedFiltersCount: number,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchFiltersMobile = injectIntl(withRouter(SearchFiltersMobileComponent));

export default SearchFiltersMobile;
