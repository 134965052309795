import React, { Component, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSort, updateSort } from '../AdminPanelPage.duck';
import css from './AdminPanelPageHeaders.module.css';

function AdminPanelPageHeaderComponent(props) {
    const sort = useSelector(getSort);
    function handleClick(){
        props.onChangeSort(props.field)
    }
    return (<th className={`${css.sortable} ${sort === props.field ? css.asc : ''} ${ sort === '-'+props.field ? css.desc : ''} `} onClick={handleClick}>{props.children}</th>)
}
export default function AdminPanelPageHeadersComponent(props) {
    const dispatch = useDispatch();
    function onChangeSort(field) {
        dispatch(updateSort(field))
    }
    return (<tr>
        {Object.keys(props.headers).sort((a, b) => {
            return props.headers[a].order - props.headers[b].order
        }).map((key) => {
            return props.headers[key].isOn && <AdminPanelPageHeaderComponent key={key} onChangeSort={onChangeSort} field={props.headers[key].field}>{props.headers[key].label}</AdminPanelPageHeaderComponent>
        })}
        {
            !props.isUsersPage ? 
            <th>Actions</th> : null
        }
    </tr>)
}