import React from 'react';
import classNames from 'classnames';
import { ExternalLink, NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build AddressLinkMaybe
const AddressLinkMaybe = props => {
  const {
    className,
    rootClassName,
    location,
    geolocation,
    showAddress,
    listingId,
    listingTitle,
    publicData,
    intl,
  } = props;
  const { address, building } = location || {};
  const { unit } = publicData || {};
  // const { lat, lng } = geolocation || {};
  // const hrefToGoogleMaps = geolocation
  //   ? `https://maps.google.com/?q=${lat},${lng}`
  //   : address
  //   ? `https://maps.google.com/?q=${encodeURIComponent(address)}`
  //   : null;
  const unitLabel = intl.formatMessage({
    id: 'TransactionPanel.unitLabel',
  });
  const fullAddress =
    typeof unit === 'string' && unit.length > 0
      ? `${address}. ${unitLabel} ${unit}`
      : typeof building === 'string' && building.length > 0
      ? `${address}. ${unitLabel} ${building}`
      : address;
  const classes = classNames(rootClassName || css.address, className);
  return showAddress ? (
    <div className={classes}>
      <h3 className={css.addressTitle}>
        <FormattedMessage id="TransactionPanel.addressTitle" />
      </h3>
      <p>
        {/* <ExternalLink href={hrefToGoogleMaps}></ExternalLink> */}
        <NamedLink name="ListingPage" params={{ id: listingId, slug: createSlug(listingTitle) }}>
          {fullAddress}
        </NamedLink>
      </p>
    </div>
  ) : null;
};

export default AddressLinkMaybe;
