import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>Welcome to trioplus.org. <br/>These terms of use ("Terms") govern your use of our website, products, and services. By using our website, you agree to these Terms. If you do not agree to these Terms, please do not use our website.</p>

      <p>Intellectual Property<br />All content on our website, including but not limited to text, graphics, logos, images, and software, is the property of TRiO Plus and is protected by United States and international copyright laws. You may not use or reproduce any content on our website without our prior written consent.</p>

      <p>User Conduct<br />You may not use our website for any unlawful purpose or in any way that could damage, disable, overburden, or impair our website. You may not use automated scripts or software to access our website or scrape or copy content from our website. You may not use our website to harass, defame, or threaten others.</p>

      <p>Disclaimer of Warranties<br/>We provide our website, products, and services "as is" and without any warranty or guarantee. We do not guarantee that our website will be error-free, uninterrupted, or free from viruses or other harmful components. We are not responsible for any damages that may result from the use of our website.</p>

      <p>Limitation of Liability<br/>In no event shall TRiO Plus be liable for any damages, including but not limited to direct, indirect, incidental, or consequential damages, arising out of the use or inability to use our website or products and services.</p>

      <p>Indemnification<br/>You agree to indemnify and hold TRiO Plus harmless from any claims, damages, liabilities, and expenses (including attorneys' fees) arising out of your use of our website or products and services.</p>

      <p>Changes to These Terms<br/>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our website.</p>

      <p>Governing Law and Jurisdiction<br/>These Terms shall be governed by and construed in accordance with the laws of the United States and the State of California. Any legal action or proceeding arising out of these Terms shall be brought in the federal or state courts located in Alameda County, California.</p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
