import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import range from 'lodash/range';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldPhoneNumberInput,
  FieldCheckboxGroup,
  FieldCheckbox,
  NamedLink,
  IconInfo,
} from '../../components';
import { Tooltip } from 'react-tooltip';

import css from './SignupForm.module.css';
import { FieldSelect } from '../../components';
const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        values,
        isUserSignupPath,
      } = fieldRenderProps;

      // email
      const emailLabel = isUserSignupPath ? intl.formatMessage({
        id: 'SignupForm.userEmailLabel',
      }) : intl.formatMessage(
        {
          id: 'SignupForm.emailLabel',
        },
        {
          info: (
            <span
              data-tooltip-id="email-tooltip"
              data-tooltip-content={intl.formatMessage({
                id: 'SignupForm.emailInfo',
              })}
            >
              <IconInfo />
            </span>
          ),
        }
      );
      const emailPlaceholder = isUserSignupPath ? intl.formatMessage({
        id: 'SignupForm.userEmailPlaceholder',
      }) : intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);
      const employeeIdRequiredMessage = intl.formatMessage({
        id: 'SignupForm.employeeIdRequired',
      });
      const employeeIdValidator = validators.validEmployeeId(employeeIdRequiredMessage);

      const yearsAtOUSDRequiredMessage = intl.formatMessage({
        id: 'SignupForm.yearsAtOUSDRequired',
      });
      const yearsAtOUSDRequired = validators.required(yearsAtOUSDRequiredMessage);
      const phoneNumberLabel = intl.formatMessage({
        id: 'SignupForm.phoneNumberLabel',
      });
      const phoneNumberRequiredMessage = intl.formatMessage({
        id: 'SignupForm.phoneNumberRequired',
      });
      const phoneNumberRequired = validators.required(phoneNumberRequiredMessage);

      const readyToMoveRequiredMessage = intl.formatMessage({
        id: 'SignupForm.readyToMoveRequired',
      });
      const readyToMoveRequired = validators.required(readyToMoveRequiredMessage);

      const preferedRentalTypeRequiredMessage = intl.formatMessage({
        id: 'SignupForm.preferedRentalTypeRequired',
      });
      const preferedRentalTypeRequired = validators.required(preferedRentalTypeRequiredMessage);
      const confirmOUSDEducatorRequiredMessage = intl.formatMessage({
        id: 'SignupForm.confirmOUSDEducatorRequired',
      });
      const confirmOUSDEducatorRequired = validators.required(confirmOUSDEducatorRequiredMessage);
      const acceptTermsAndPolicyRequiredMessage = intl.formatMessage({
        id: 'SignupForm.acceptTermsAndPolicyRequired',
      });

      const roleLabel = intl.formatMessage({
        id: 'SignupForm.roleLabel',
      });
      const rolePlaceholder = intl.formatMessage({
        id: 'SignupForm.rolePlaceholder',
      });

      const genderPronounLabel = intl.formatMessage({
        id: 'SignupForm.genderPronounLabel',
      });
      const genderPronounPlaceholder = intl.formatMessage({
        id: 'SignupForm.genderPronounPlaceholder',
      });
      const heLabel = intl.formatMessage({
        id: 'SignupForm.heLabel',
      });
      const sheLabel = intl.formatMessage({
        id: 'SignupForm.sheLabel',
      });
      const theyLabel = intl.formatMessage({
        id: 'SignupForm.theyLabel',
      });
      const otherLabel = intl.formatMessage({
        id: 'SignupForm.otherLabel',
      });
      const otherGenderLabel = intl.formatMessage({
        id: 'SignupForm.otherGenderLabel',
      });
      const otherGenderPlaceholder = intl.formatMessage({
        id: 'SignupForm.otherGenderPlaceholder',
      });

      const workingAsTeacherLabel = intl.formatMessage(
        {
          id: 'SignupForm.workingAsTeacherLabel',
        },
        {
          info: (
            <span
              data-tooltip-id="working-as-teacher-tooltip"
              data-tooltip-content={intl.formatMessage({
                id: 'SignupForm.workingAsTeacherInfo',
              })}
            >
              <IconInfo />
            </span>
          ),
        }
      );
      const workingAsTeacherRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.workingAsTeacherRequired',
        })
      );
      const workingAsTeacherPlaceholder = intl.formatMessage({
        id: 'SignupForm.workingAsTeacherPlaceholder',
      });
      const workingAsTeacherOptionYes = intl.formatMessage({
        id: 'SignupForm.workingAsTeacherOptionYes',
      });
      const workingAsTeacherOptionIncomingTeacher = intl.formatMessage({
        id: 'SignupForm.workingAsTeacherOptionIncomingTeacher',
      });
      const workingAsTeacherOptionNo = intl.formatMessage({
        id: 'SignupForm.workingAsTeacherOptionNo',
      });
      const classifiedToTeacherProgramLabel = intl.formatMessage({
        id: 'SignupForm.classifiedToTeacherProgramLabel',
      });
      const classifiedToTeacherProgramRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.classifiedToTeacherProgramRequired',
        })
      );
      const classifiedToTeacherProgramPlaceholder = intl.formatMessage({
        id: 'SignupForm.classifiedToTeacherProgramPlaceholder',
      });
      const classifiedToTeacherProgramOptionYes = intl.formatMessage({
        id: 'SignupForm.classifiedToTeacherProgramOptionYes',
      });
      const classifiedToTeacherProgramOptionNo = intl.formatMessage({
        id: 'SignupForm.classifiedToTeacherProgramOptionNo',
      });
      const startYearWithOUSDLabel = intl.formatMessage({
        id: 'SignupForm.startYearWithOUSDLabel',
      });
      const startYearWithOUSDRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.startYearWithOUSDRequired',
        })
      );
      const startYearWithOUSDPlaceholder = intl.formatMessage({
        id: 'SignupForm.startYearWithOUSDPlaceholder',
      });
      const OUSDEmployerIDLabel = intl.formatMessage({
        id: 'SignupForm.OUSDEmployerIDLabel',
      });
      const dontHaveEmployerIDLabel = intl.formatMessage({
        id: 'SignupForm.dontHaveEmployerIDLabel',
      });
      const movingLabel = intl.formatMessage({
        id: 'SignupForm.movingLabel',
      });
      const movingRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.movingRequired',
        })
      );
      const movingPlaceholder = intl.formatMessage({
        id: 'SignupForm.movingPlaceholder',
      });
      const movingOptionNow = intl.formatMessage({
        id: 'SignupForm.movingOptionNow',
      });
      const movingOptionNextFewMonths = intl.formatMessage({
        id: 'SignupForm.movingOptionNextFewMonths',
      });
      const movingOptionLater = intl.formatMessage({
        id: 'SignupForm.movingOptionLater',
      });
      const unitSizeLabel = intl.formatMessage({
        id: 'SignupForm.unitSizeLabel',
      });
      const unitSizePlaceholder = intl.formatMessage({
        id: 'SignupForm.unitSizePlaceholder',
      });
      const unitSizeOptionStudio = intl.formatMessage({
        id: 'SignupForm.unitSizeOptionStudio',
      });
      const unitSizeOptionOneBedroom = intl.formatMessage({
        id: 'SignupForm.unitSizeOptionOneBedroom',
      });
      const unitSizeOptionTwoBedrooms = intl.formatMessage({
        id: 'SignupForm.unitSizeOptionTwoBedrooms',
      });
      const unitSizeOptionThreeBedroomsOrMore = intl.formatMessage({
        id: 'SignupForm.unitSizeOptionThreeBedroomsOrMore',
      });
      const neighborhoodsLabel = intl.formatMessage({
        id: 'SignupForm.neighborhoodsLabel',
      });
      const householdAnnualIncomeLabel = intl.formatMessage(
        {
          id: 'SignupForm.householdAnnualIncomeLabel',
        },
        {
          info: (
            <span
              data-tooltip-id="household-annual-income-tooltip"
              data-tooltip-content={intl.formatMessage({
                id: 'SignupForm.householdAnnualIncomeInfo',
              })}
            >
              <IconInfo />
            </span>
          ),
        }
      );
      const householdAnnualIncomePlaceholder = intl.formatMessage({
        id: 'SignupForm.householdAnnualIncomePlaceholder',
      });
      const householdAnnualIncomeValidator = validators.validHouseholdAnnualIncome(
        intl.formatMessage({
          id: 'SignupForm.householdAnnualIncomeRequiredMessage',
        })
      );
      const householdAnnualIncomeRatherNotAnswerLabel = intl.formatMessage({
        id: 'SignupForm.householdAnnualIncomeRatherNotAnswerLabel',
      });
      const creditScoreLabel = intl.formatMessage(
        {
          id: 'SignupForm.creditScoreLabel',
        },
        {
          info: (
            <span
              data-tooltip-id="credit-score-tooltip"
              data-tooltip-content={intl.formatMessage({
                id: 'SignupForm.creditScoreInfo',
              })}
            >
              <IconInfo />
            </span>
          ),
        }
      );
      const creditScorePlaceholder = intl.formatMessage({
        id: 'SignupForm.creditScorePlaceholder',
      });
      const creditScoreValidator = validators.validCreditScore(
        intl.formatMessage({
          id: 'SignupForm.creditScoreRequiredMessage',
        })
      );
      const creditScoreRatherNotAnswerLabel = intl.formatMessage({
        id: 'SignupForm.creditScoreRatherNotAnswerLabel',
      });

      const emailAgreementRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailAgreementRequiredMessage',
        })
      );

      const contactAgreementRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.contactAgreementRequiredMessage',
        })
      );

      const acceptTermsAndPolicyRequired = validators.required(acceptTermsAndPolicyRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      const toolTipStyle = { maxWidth: '300px', zIndex: 2 };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <Tooltip id="email-tooltip" openOnClick={true} style={toolTipStyle} />
          <Tooltip id="working-as-teacher-tooltip" openOnClick={true} style={toolTipStyle} />
          <Tooltip id="household-annual-income-tooltip" openOnClick={true} style={toolTipStyle} />
          <Tooltip id="credit-score-tooltip" openOnClick={true} style={toolTipStyle} />
          <div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>
            {!isUserSignupPath ? (
              <>
                <FieldSelect
                  id={formId ? `${formId}.genderPronoun` : 'genderPronoun'}
                  name="genderPronoun"
                  className={css.field}
                  label={genderPronounLabel}
                >
                  <option disabled value="">
                    {genderPronounPlaceholder}
                  </option>
                  <option value={'He/Him'}>{heLabel}</option>
                  <option value={'She/Hers'}>{sheLabel}</option>
                  <option value={'They/Them'}>{theyLabel}</option>
                  <option value={'Other'}>{otherLabel}</option>
                </FieldSelect>

                {values?.genderPronoun === 'Other' ? (
                  <FieldTextInput
                    className={css.field}
                    type="text"
                    id={formId ? `${formId}.otherGender` : 'otherGender'}
                    name="otherGender"
                    autoComplete="otherGender"
                    label={otherGenderLabel}
                    placeholder={otherGenderPlaceholder}
                  />
                ) : null}
              </>
            ) : (
              <>
                <FieldTextInput
                  className={css.field}
                  type="text"
                  id={formId ? `${formId}.role` : 'role'}
                  name="role"
                  autoComplete="role"
                  label={roleLabel}
                  placeholder={rolePlaceholder}
                />
                <FieldTextInput
                  className={css.field}
                  type="hidden"
                  id={formId ? `${formId}.isRoleUser` : 'isRoleUser'}
                  name="isRoleUser"
                  defaultValue={isUserSignupPath ? true : false}
                />
              </>
            )}
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />
            <div>
              {!isUserSignupPath ? (
                <>
                  <FieldPhoneNumberInput
                    className={css.password}
                    type="text"
                    id={'phoneNumber'}
                    name="phoneNumber"
                    autoComplete="phoneNumber"
                    label={phoneNumberLabel}
                    placeholder={'(xxx) xxx-xxxx'}
                    validate={phoneNumberRequired}
                  />

                  <FieldSelect
                    className={css.field}
                    name={'workingAsTeacher'}
                    id={formId ? `${formId}.workingAsTeacher` : 'workingAsTeacher'}
                    label={workingAsTeacherLabel}
                    validate={workingAsTeacherRequired}
                  >
                    <option disabled value="">
                      {workingAsTeacherPlaceholder}
                    </option>
                    <option value={'yes'}>{workingAsTeacherOptionYes}</option>
                    <option value={'incomingTeacher'}>
                      {workingAsTeacherOptionIncomingTeacher}
                    </option>
                    <option value={'no'}>{workingAsTeacherOptionNo}</option>
                  </FieldSelect>

                  <FieldSelect
                    className={css.field}
                    name={'classifiedToTeacherProgram'}
                    id={
                      formId ? `${formId}.classifiedToTeacherProgram` : 'classifiedToTeacherProgram'
                    }
                    label={classifiedToTeacherProgramLabel}
                    validate={classifiedToTeacherProgramRequired}
                  >
                    <option disabled value="">
                      {classifiedToTeacherProgramPlaceholder}
                    </option>
                    <option value={'Yes'}>{classifiedToTeacherProgramOptionYes}</option>
                    <option value={'No'}>{classifiedToTeacherProgramOptionNo}</option>
                  </FieldSelect>

                  <FieldSelect
                    className={css.field}
                    name={'startYearWithOUSD'}
                    id={formId ? `${formId}.startYearWithOUSD` : 'startYearWithOUSD'}
                    label={startYearWithOUSDLabel}
                    validate={startYearWithOUSDRequired}
                  >
                    <option disabled value="">
                      {startYearWithOUSDPlaceholder}
                    </option>
                    {range(1950, 2028)
                      .reverse()
                      .map(y => (
                        <option value={y}>{y.toString()}</option>
                      ))}
                  </FieldSelect>

                  <FieldTextInput
                    className={css.field}
                    type="text"
                    id={'OUSDEmployerID'}
                    name="OUSDEmployerID"
                    autoComplete="OUSDEmployerID"
                    label={OUSDEmployerIDLabel}
                    placeholder={'0'}
                    validate={employeeIdValidator}
                  />
                  <FieldCheckbox
                    id={formId ? `${formId}.dontHaveEmployerID"` : 'dontHaveEmployerID'}
                    name="dontHaveEmployerID"
                    label={dontHaveEmployerIDLabel}
                    value={true}
                    className={css.checkbox}
                  />

                  <FieldSelect
                    className={css.field}
                    name={'moving'}
                    id={formId ? `${formId}.moving` : 'moving'}
                    label={movingLabel}
                    validate={movingRequired}
                  >
                    <option disabled value="">
                      {movingPlaceholder}
                    </option>
                    <option value={'now'}>{movingOptionNow}</option>
                    <option value={'nextFewMonths'}>{movingOptionNextFewMonths}</option>
                    <option value={'later'}>{movingOptionLater}</option>
                  </FieldSelect>

                  <FieldSelect
                    className={css.field}
                    name={'unitSize'}
                    id={formId ? `${formId}.unitSize` : 'unitSize'}
                    label={unitSizeLabel}
                  >
                    <option disabled value="">
                      {unitSizePlaceholder}
                    </option>
                    <option value={'studio'}>{unitSizeOptionStudio}</option>
                    <option value={'oneBedroom'}>{unitSizeOptionOneBedroom}</option>
                    <option value={'twoBedrooms'}>{unitSizeOptionTwoBedrooms}</option>
                    <option value={'threeBedroomsOrMore'}>
                      {unitSizeOptionThreeBedroomsOrMore}
                    </option>
                  </FieldSelect>

                  <FieldCheckboxGroup
                    className={css.neighborhoods}
                    id={formId ? `${formId}.neighborhoods` : 'neighborhoods'}
                    name={'neighborhoods'}
                    label={neighborhoodsLabel}
                    options={[
                      {
                        key: 'East Oakland',
                        label: 'East Oakland',
                      },
                      {
                        key: 'Downtown',
                        label: 'Downtown',
                      },
                      {
                        key: 'West Oakland',
                        label: 'West Oakland',
                      },
                      {
                        key: 'North Oakland',
                        label: 'North Oakland',
                      },
                      {
                        key: 'Oakland Hills',
                        label: 'Oakland Hills',
                      },
                    ]}
                  />

                  <FieldTextInput
                    className={css.field}
                    type="text"
                    id={formId ? `${formId}.householdAnnualIncome` : 'householdAnnualIncome'}
                    name="householdAnnualIncome"
                    autoComplete="householdAnnualIncome"
                    label={householdAnnualIncomeLabel}
                    placeholder={householdAnnualIncomePlaceholder}
                    validate={householdAnnualIncomeValidator}
                  />

                  <FieldCheckbox
                    id={
                      formId
                        ? `${formId}.householdAnnualIncomeRatherNotAnswer"`
                        : 'householdAnnualIncomeRatherNotAnswer'
                    }
                    name="householdAnnualIncomeRatherNotAnswer"
                    label={householdAnnualIncomeRatherNotAnswerLabel}
                    value={true}
                    className={css.checkbox}
                  />

                  <FieldTextInput
                    className={css.field}
                    type="text"
                    id={formId ? `${formId}.creditScore` : 'creditScore'}
                    name="creditScore"
                    autoComplete="creditScore"
                    label={creditScoreLabel}
                    placeholder={creditScorePlaceholder}
                    validate={creditScoreValidator}
                  />

                  <FieldCheckbox
                    id={
                      formId
                        ? `${formId}.creditScoreRatherNotAnswer"`
                        : 'creditScoreRatherNotAnswer'
                    }
                    name="creditScoreRatherNotAnswer"
                    label={creditScoreRatherNotAnswerLabel}
                    value={true}
                    className={css.checkbox}
                  />
                </>
              ) : null}
              <div>
                <div className={css.checkboxes}>
                  <FieldTextInput
                    className={css.name}
                    type="checkbox"
                    id={'acceptTermsAndPolicy'}
                    name="acceptTermsAndPolicy"
                    required={true}
                    validate={acceptTermsAndPolicyRequired}
                  />
                  <span className={css.acceptTermsAndPolicyLabel}>
                    {intl.formatMessage(
                      { id: 'SignupForm.acceptTermsAndPolicy' },
                      {
                        tosLink: (
                          <NamedLink name="TermsOfServicePage" className={css.link}>
                            {intl.formatMessage({ id: 'SignupForm.termsOfService' })}
                          </NamedLink>
                        ),
                        ppLink: (
                          <NamedLink name="PrivacyPolicyPage" className={css.link}>
                            {intl.formatMessage({ id: 'SignupForm.privacyPolicy' })}
                          </NamedLink>
                        ),
                      }
                    )}
                  </span>
                </div>

                <div className={css.checkboxes}>
                  <FieldTextInput
                    className={css.name}
                    type="checkbox"
                    id={'emailAgreement'}
                    name="emailAgreement"
                    required={true}
                    validate={emailAgreementRequired}
                  />
                  {intl.formatMessage({ id: 'SignupForm.emailAgreement' })}
                </div>
                <div className={css.checkboxes}>
                  <FieldTextInput
                    className={css.password}
                    type="checkbox"
                    id={'contactAgreement'}
                    name="contactAgreement"
                    required={true}
                    validate={contactAgreementRequired}
                  />
                  {intl.formatMessage({ id: 'SignupForm.contactAgreement' })}
                </div>
              </div>
            </div>
          </div>

          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
