import React, { useEffect, useRef } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import CustomTagsFieldMaybe from './CustomTagsFieldMaybe';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw } from 'draft-js';

import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 1000;
const DESCRIPTION_MAX_LENGTH = 5000;

let draftToHtml = null;
let Editor = null;

const importEditorDependecies = () => {
  import('draftjs-to-html').then(h => {
    draftToHtml = h.default;
  });

  import('react-draft-wysiwyg').then(h => {
    Editor = h.Editor;
  });
};

typeof window !== 'undefined' ? importEditorDependecies() : null;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        categories,
        tags,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        editorState,
        setEditorState,
        isValidDescription,
        setIsValidDescription,
      } = formRenderProps;
      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionInvalidMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.descriptionInvalid' },
        {
          maxLength: DESCRIPTION_MAX_LENGTH,
        }
      );

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || !isValidDescription;
      const titleInputRef = useRef();

      useEffect(() => {
        if (titleInputRef.current && titleInputRef.current.value.startsWith('New Listing-')) {
          titleInputRef.current.focus();
          titleInputRef.current.select();
        }
      }, []);

      const onEditorStateChange = editorState => {
        setEditorState(editorState);
        const descriptionValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (
          !!descriptionValue &&
          descriptionValue?.length > 0 &&
          descriptionValue?.length <= DESCRIPTION_MAX_LENGTH
        ) {
          formRenderProps.form.change('description', descriptionValue);
          if (!isValidDescription) {
            setIsValidDescription(true);
          }
        } else {
          setIsValidDescription(false);
        }
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
            inputRef={titleInputRef}
          />

          {/* <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          /> */}

          <div className={css.description}>
            <label className={css.descriptionLabel} htmlFor="description">
              {descriptionMessage}
            </label>
            <Editor
              id="description"
              placeholder={descriptionPlaceholderMessage}
              editorClassName={css.descriptionEditor}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              stripPastedStyles={true}
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'fontSize',
                  'fontFamily',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'remove',
                  'history',
                ],
                fontFamily: {
                  options: ['Arial', 'poppins'],
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                },
              }}
            />
            {!isValidDescription ? (
              <div className={css.error}>{descriptionInvalidMessage}</div>
            ) : null}
          </div>

          <CustomCategorySelectFieldMaybe
            id="category"
            name="category"
            categories={categories}
            intl={intl}
          />

          <CustomTagsFieldMaybe id="tags" name="tags" tags={tags} intl={intl} />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
