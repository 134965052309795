import React, { useState, useEffect } from 'react';
import css from "./AddButton.module.css";
import { FormattedMessage } from '../../util/reactIntl';
import { useLocation } from 'react-router-dom';

export const AddButtonComponent = ({ onClick, disabled }) => {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const location = useLocation();
    const isEditDescription = location.pathname.includes('/edit/') && location.pathname.includes('/l/');

    const handleClick = () => {
        if (!buttonDisabled && !isEditDescription) {
            onClick();
            setButtonDisabled(true);
        }
    };

    useEffect(() => {
        if (buttonDisabled) {
            const timer = setTimeout(() => {
                setButtonDisabled(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [buttonDisabled]);

    return (
        <a
            className={`${css.createListingLink} ${(buttonDisabled || isEditDescription) ? css.disabled : ''}`}
            onClick={handleClick}
        >
            <span className={css.inbox}>
                <FormattedMessage id="TopbarDesktop.createListing" />
            </span>
        </a>
    );
};
