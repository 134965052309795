export const listingHeaders = {
  address: {
    id: 'address',
    isOn: true,
    field: 'address',
    label: 'Address',
    path: 'attributes.publicData.location.address',
    order: 0,
  },
  status: {
    id: 'status',
    isOn: false,
    field: 'status',
    label: 'Status',
    path: 'attributes.publicData.status',
    order: 0,
  },
  transactions: {
    id: 'transactions',
    isOn: true,
    field: 'transactions',
    label: '# of Inquiries',
    path: 'attributes.metadata.transactions',
    order: 0,
  },
  boost: {
    id: 'boost',
    isOn: false,
    field: 'boost',
    label: 'Boost',
    path: 'attributes.publicData.boost',
    order: 0,
  },
  category: {
    id: 'category',
    isOn: true,
    field: 'category',
    label: 'Unit Type',
    path: 'attributes.publicData.category',
    order: 1,
  },
  streetNumber: {
    id: 'streetNumber',
    isOn: true,
    field: 'streetNumber',
    label: 'Street #',
    path: 'attributes.publicData.streetNumber',
    order: 2,
  },
  streetName: {
    id: 'streetName',
    isOn: true,
    field: 'streetName',
    label: 'Street Name',
    path: 'attributes.publicData.streetName',
    order: 3,
  },
  unit: {
    id: 'unit',
    isOn: true,
    field: 'unit',
    label: 'Unit',
    path: 'attributes.publicData.unit',
    order: 4,
  },
  zipCode: {
    id: 'zipCode',
    isOn: true,
    field: 'zipCode',
    label: 'Zip Code',
    path: 'attributes.publicData.zipCode',
    order: 5,
  },
  city: {
    id: 'city',
    isOn: true,
    field: 'city',
    label: 'City',
    path: 'attributes.publicData.city',
    order: 6,
  },
  title: {
    id: 'title',
    isOn: true,
    field: 'title',
    label: 'Title',
    path: 'attributes.title',
    order: 7,
  },
  description: {
    id: 'description',
    isOn: true,
    field: 'description',
    label: 'Description',
    path: 'attributes.description',
    order: 8,
  },
  name: {
    id: 'name',
    isOn: false,
    field: 'name',
    label: 'Building Name',
    path: 'attributes.publicData.name',
    order: 9,
  },
  owner: {
    id: 'owner',
    isOn: false,
    field: 'owner',
    label: 'Owner',
    path: 'attributes.publicData.owner',
    order: 10,
  },
  price: {
    id: 'price',
    isOn: true,
    field: 'price',
    label: 'Base Rent',
    path: 'attributes.price.amount',
    convertToCurrency: true,
    order: 11,
  },
  effectiveRent: {
    id: 'effectiveRent',
    isOn: false,
    field: 'effectiveRent',
    label: 'Effective Rent',
    path: 'attributes.publicData.effectiveRent',
    convertToCurrency: true,
    order: 12,
  },
  concession: {
    id: 'concession',
    isOn: false,
    field: 'concession',
    label: 'Concession',
    path: 'attributes.publicData.concession',
    order: 13,
  },
  inputDate: {
    id: 'inputDate',
    isOn: false,
    field: 'inputDate',
    label: 'Input Date',
    path: 'attributes.publicData.inputDate',
    order: 14,
  },
  activeDate: {
    id: 'activeDate',
    isOn: false,
    field: 'activeDate',
    label: 'Active Date',
    path: 'attributes.publicData.activeDate',
    order: 15,
  },
  leaseSignDate: {
    id: 'leaseSignDate',
    isOn: false,
    field: 'leaseSignDate',
    label: 'Lease Sign Date',
    path: 'attributes.publicData.leaseSignDate',
    order: 16,
  },
  leaseStartDate: {
    id: 'leaseStartDate',
    isOn: false,
    field: 'leaseStartDate',
    label: 'Lease Start Date',
    path: 'attributes.publicData.leaseStartDate',
    order: 17,
  },
  leaseEndDate: {
    id: 'leaseEndDate',
    isOn: false,
    field: 'leaseEndDate',
    label: 'Lease End Date',
    path: 'attributes.publicData.leaseEndDate',
    order: 18,
  },
  propertyManager: {
    id: 'propertyManager',
    isOn: false,
    field: 'propertyManager',
    label: 'Property Manager',
    path: 'attributes.publicData.propertyManager',
    order: 19,
  },
  teacherFirstName: {
    id: 'teacherFirstName',
    isOn: false,
    field: 'teacherFirstName',
    label: 'Teacher First Name',
    path: 'attributes.publicData.teacherFirstName',
    order: 20,
  },
  teacherLastName: {
    id: 'teacherLastName',
    isOn: false,
    field: 'teacherLastName',
    label: 'Teacher Last Name',
    path: 'attributes.publicData.teacherLastName',
    order: 21,
  },
  teacherSchool: {
    id: 'teacherSchool',
    isOn: false,
    field: 'teacherSchool',
    label: 'Teacher School',
    path: 'attributes.publicData.teacherSchool',
    order: 22,
  },
  teacherYearsAtOUSD: {
    id: 'teacherYearsAtOUSD',
    isOn: false,
    field: 'teacherYearsAtOUSD',
    label: 'Teacher Years at OUSD',
    path: 'attributes.publicData.teacherYearsAtOUSD',
    order: 23,
  },
};

export const userHeaders = {
  firstName: {
    id: 'firstName',
    isOn: true,
    field: 'firstName',
    label: 'First Name',
    path: 'attributes.profile.firstName',
    order: 0,
  },
  lastName: {
    id: 'lastName',
    isOn: true,
    field: 'lastName',
    label: 'Last Name',
    path: 'attributes.profile.lastName',
    order: 1,
  },
  isRoleUser: {
    id: 'isRoleUser',
    isOn: true,
    field: 'isRoleUser',
    label: 'Simple sign up?',
    path: 'attributes.profile.protectedData.isRoleUser',
    order: 2,
  },
  genderPronoun: {
    id: 'genderPronoun',
    isOn: true,
    field: 'genderPronoun',
    label: 'Gender Pronouns',
    path: 'attributes.profile.protectedData.genderPronoun',
    order: 2,
  },
  moving: {
    id: 'moving',
    isOn: false,
    field: 'moving',
    label: 'Moving',
    path: 'attributes.profile.protectedData.moving',
    order: 3,
  },
  neighborhoods: {
    id: 'neighborhoods',
    isOn: true,
    field: 'neighborhoods',
    label: 'Preferred neighborhoods',
    path: 'attributes.profile.protectedData.neighborhoods',
    order: 4,
  },
  startYearWithOUSD: {
    id: 'startYearWithOUSD',
    isOn: false,
    field: 'startYearWithOUSD',
    label: 'Start Year With OUSD',
    path: 'attributes.profile.protectedData.startYearWithOUSD',
    order: 5,
  },
  unitSize: {
    id: 'unitSize',
    isOn: false,
    field: 'unitSize',
    label: 'Preferred unit size',
    path: 'attributes.profile.protectedData.unitSize',
    order: 6,
  },
  workingAsTeacher: {
    id: 'workingAsTeacher',
    isOn: false,
    field: 'workingAsTeacher',
    label: 'Working As Teacher',
    path: 'attributes.profile.protectedData.workingAsTeacher',
    order: 7,
  },
  householdAnnualIncomeRatherNotAnswer: {
    id: 'householdAnnualIncomeRatherNotAnswer',
    isOn: false,
    field: 'householdAnnualIncomeRatherNotAnswer',
    label: 'Household Annual Income Rather Not Answer',
    path: 'attributes.profile.protectedData.householdAnnualIncomeRatherNotAnswer',
    order: 8,
  },
  creditScoreRatherNotAnswer: {
    id: 'creditScoreRatherNotAnswer',
    isOn: false,
    field: 'creditScoreRatherNotAnswer',
    label: 'Cedit Score Rather Not Answer',
    path: 'attributes.profile.protectedData.creditScoreRatherNotAnswer',
    order: 9,
  },
  email: {
    id: 'email',
    isOn: true,
    field: 'email',
    label: 'Email',
    path: 'attributes.email',
    order: 10,
  },
  phoneNumber: {
    id: 'phoneNumber',
    isOn: false,
    field: 'phoneNumber',
    label: 'Phone Number',
    path: 'attributes.profile.protectedData.phoneNumber',
    order: 11,
  },
  OUSDEmployerID: {
    id: 'OUSDEmployerID',
    isOn: true,
    field: 'OUSDEmployerID',
    label: 'OUSD Employer ID',
    path: 'attributes.profile.protectedData.OUSDEmployerID',
    order: 12,
  },
  id: {
    id: 'id',
    isOn: true,
    field: 'id',
    label: 'User ID',
    path: 'id.uuid',
    order: 13,
  },
  acceptTermsAndPolicy: {
    id: 'acceptTermsAndPolicy',
    isOn: false,
    field: 'acceptTermsAndPolicy',
    label: 'Accept Terms And Policy',
    // path: ["attributes.profile.protectedData.acceptTermsAndPolicy", "attributes.profile.protectedData.accpetTermsAndPolicy"],
    path: 'attributes.profile.protectedData.acceptTermsAndPolicy',
    order: 14,
  },
  classifiedToTeacherProgram: {
    id: 'classifiedToTeacherProgram',
    isOn: false,
    field: 'classifiedToTeacherProgram',
    label: 'Classified To Teacher Program',
    path: 'attributes.profile.protectedData.classifiedToTeacherProgram',
    order: 15,
  },
  contactAgreement: {
    id: 'contactAgreement',
    isOn: false,
    field: 'contactAgreement',
    label: 'Contact Agreement',
    path: 'attributes.profile.protectedData.contactAgreement',
    order: 16,
  },
  emailAgreement: {
    id: 'emailAgreement',
    isOn: false,
    field: 'emailAgreement',
    label: 'Email Agreement',
    path: 'attributes.profile.protectedData.emailAgreement',
    order: 17,
  },
};
