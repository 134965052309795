import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Form,
  Avatar,
  Button,
  ImageFromFile,
  IconSpinner,
  FieldTextInput,
  IconInfo,
  FieldSelect,
  FieldPhoneNumberInput,
  FieldCheckbox,
  FieldCheckboxGroup,
  NamedLink,
} from '../../components';
import { Tooltip } from 'react-tooltip';
import range from 'lodash/range';

import css from './ProfileSettingsForm.module.css';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const {
            className,
            formId,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // Bio
          const bioLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.bioLabel',
          });
          const bioPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.bioPlaceholder',
          });

          const employeeIdRequiredMessage = intl.formatMessage({
            id: 'SignupForm.employeeIdRequired',
          });
          const employeeIdValidator = validators.validEmployeeId(employeeIdRequiredMessage);

          const yearsAtOUSDRequiredMessage = intl.formatMessage({
            id: 'SignupForm.yearsAtOUSDRequired',
          });
          const yearsAtOUSDRequired = validators.required(yearsAtOUSDRequiredMessage);
          const phoneNumberLabel = intl.formatMessage({
            id: 'SignupForm.phoneNumberLabel',
          });
          const phoneNumberRequiredMessage = intl.formatMessage({
            id: 'SignupForm.phoneNumberRequired',
          });
          const phoneNumberRequired = validators.required(phoneNumberRequiredMessage);

          const readyToMoveRequiredMessage = intl.formatMessage({
            id: 'SignupForm.readyToMoveRequired',
          });
          const readyToMoveRequired = validators.required(readyToMoveRequiredMessage);

          const preferedRentalTypeRequiredMessage = intl.formatMessage({
            id: 'SignupForm.preferedRentalTypeRequired',
          });
          const preferedRentalTypeRequired = validators.required(preferedRentalTypeRequiredMessage);
          const confirmOUSDEducatorRequiredMessage = intl.formatMessage({
            id: 'SignupForm.confirmOUSDEducatorRequired',
          });
          const confirmOUSDEducatorRequired = validators.required(
            confirmOUSDEducatorRequiredMessage
          );

          const roleLabel = intl.formatMessage({
            id: 'SignupForm.roleLabel',
          });
          const rolePlaceholder = intl.formatMessage({
            id: 'SignupForm.rolePlaceholder',
          });

          const genderPronounLabel = intl.formatMessage({
            id: 'SignupForm.genderPronounLabel',
          });
          const genderPronounPlaceholder = intl.formatMessage({
            id: 'SignupForm.genderPronounPlaceholder',
          });
          const heLabel = intl.formatMessage({
            id: 'SignupForm.heLabel',
          });
          const sheLabel = intl.formatMessage({
            id: 'SignupForm.sheLabel',
          });
          const theyLabel = intl.formatMessage({
            id: 'SignupForm.theyLabel',
          });
          const otherLabel = intl.formatMessage({
            id: 'SignupForm.otherLabel',
          });
          const otherGenderLabel = intl.formatMessage({
            id: 'SignupForm.otherGenderLabel',
          });
          const otherGenderPlaceholder = intl.formatMessage({
            id: 'SignupForm.otherGenderPlaceholder',
          });

          const workingAsTeacherLabel = intl.formatMessage(
            {
              id: 'SignupForm.workingAsTeacherLabel',
            },
            {
              info: (
                <span
                  data-tooltip-id="working-as-teacher-tooltip"
                  data-tooltip-content={intl.formatMessage({
                    id: 'SignupForm.workingAsTeacherInfo',
                  })}
                >
                  <IconInfo />
                </span>
              ),
            }
          );
          const workingAsTeacherRequired = validators.required(
            intl.formatMessage({
              id: 'SignupForm.workingAsTeacherRequired',
            })
          );
          const workingAsTeacherPlaceholder = intl.formatMessage({
            id: 'SignupForm.workingAsTeacherPlaceholder',
          });
          const workingAsTeacherOptionYes = intl.formatMessage({
            id: 'SignupForm.workingAsTeacherOptionYes',
          });
          const workingAsTeacherOptionIncomingTeacher = intl.formatMessage({
            id: 'SignupForm.workingAsTeacherOptionIncomingTeacher',
          });
          const workingAsTeacherOptionNo = intl.formatMessage({
            id: 'SignupForm.workingAsTeacherOptionNo',
          });
          const classifiedToTeacherProgramLabel = intl.formatMessage({
            id: 'SignupForm.classifiedToTeacherProgramLabel',
          });
          const classifiedToTeacherProgramRequired = validators.required(
            intl.formatMessage({
              id: 'SignupForm.classifiedToTeacherProgramRequired',
            })
          );
          const classifiedToTeacherProgramPlaceholder = intl.formatMessage({
            id: 'SignupForm.classifiedToTeacherProgramPlaceholder',
          });
          const classifiedToTeacherProgramOptionYes = intl.formatMessage({
            id: 'SignupForm.classifiedToTeacherProgramOptionYes',
          });
          const classifiedToTeacherProgramOptionNo = intl.formatMessage({
            id: 'SignupForm.classifiedToTeacherProgramOptionNo',
          });
          const startYearWithOUSDLabel = intl.formatMessage({
            id: 'SignupForm.startYearWithOUSDLabel',
          });
          const startYearWithOUSDRequired = validators.required(
            intl.formatMessage({
              id: 'SignupForm.startYearWithOUSDRequired',
            })
          );
          const startYearWithOUSDPlaceholder = intl.formatMessage({
            id: 'SignupForm.startYearWithOUSDPlaceholder',
          });
          const OUSDEmployerIDLabel = intl.formatMessage({
            id: 'SignupForm.OUSDEmployerIDLabel',
          });
          const dontHaveEmployerIDLabel = intl.formatMessage({
            id: 'SignupForm.dontHaveEmployerIDLabel',
          });
          const movingLabel = intl.formatMessage({
            id: 'SignupForm.movingLabel',
          });
          const movingRequired = validators.required(
            intl.formatMessage({
              id: 'SignupForm.movingRequired',
            })
          );
          const movingPlaceholder = intl.formatMessage({
            id: 'SignupForm.movingPlaceholder',
          });
          const movingOptionNow = intl.formatMessage({
            id: 'SignupForm.movingOptionNow',
          });
          const movingOptionNextFewMonths = intl.formatMessage({
            id: 'SignupForm.movingOptionNextFewMonths',
          });
          const movingOptionLater = intl.formatMessage({
            id: 'SignupForm.movingOptionLater',
          });
          const unitSizeLabel = intl.formatMessage({
            id: 'SignupForm.unitSizeLabel',
          });
          const unitSizePlaceholder = intl.formatMessage({
            id: 'SignupForm.unitSizePlaceholder',
          });
          const unitSizeOptionStudio = intl.formatMessage({
            id: 'SignupForm.unitSizeOptionStudio',
          });
          const unitSizeOptionOneBedroom = intl.formatMessage({
            id: 'SignupForm.unitSizeOptionOneBedroom',
          });
          const unitSizeOptionTwoBedrooms = intl.formatMessage({
            id: 'SignupForm.unitSizeOptionTwoBedrooms',
          });
          const unitSizeOptionThreeBedroomsOrMore = intl.formatMessage({
            id: 'SignupForm.unitSizeOptionThreeBedroomsOrMore',
          });
          const neighborhoodsLabel = intl.formatMessage({
            id: 'SignupForm.neighborhoodsLabel',
          });
          const householdAnnualIncomeLabel = intl.formatMessage(
            {
              id: 'SignupForm.householdAnnualIncomeLabel',
            },
            {
              info: (
                <span
                  data-tooltip-id="household-annual-income-tooltip"
                  data-tooltip-content={intl.formatMessage({
                    id: 'SignupForm.householdAnnualIncomeInfo',
                  })}
                >
                  <IconInfo />
                </span>
              ),
            }
          );
          const householdAnnualIncomePlaceholder = intl.formatMessage({
            id: 'SignupForm.householdAnnualIncomePlaceholder',
          });
          const householdAnnualIncomeValidator = validators.validHouseholdAnnualIncome(
            intl.formatMessage({
              id: 'SignupForm.householdAnnualIncomeRequiredMessage',
            })
          );
          const householdAnnualIncomeRatherNotAnswerLabel = intl.formatMessage({
            id: 'SignupForm.householdAnnualIncomeRatherNotAnswerLabel',
          });
          const creditScoreLabel = intl.formatMessage(
            {
              id: 'SignupForm.creditScoreLabel',
            },
            {
              info: (
                <span
                  data-tooltip-id="credit-score-tooltip"
                  data-tooltip-content={intl.formatMessage({
                    id: 'SignupForm.creditScoreInfo',
                  })}
                >
                  <IconInfo />
                </span>
              ),
            }
          );
          const creditScorePlaceholder = intl.formatMessage({
            id: 'SignupForm.creditScorePlaceholder',
          });
          const creditScoreValidator = validators.validCreditScore(
            intl.formatMessage({
              id: 'SignupForm.creditScoreRequiredMessage',
            })
          );
          const creditScoreRatherNotAnswerLabel = intl.formatMessage({
            id: 'SignupForm.creditScoreRatherNotAnswerLabel',
          });

          const toolTipStyle = { maxWidth: '300px', zIndex: 2 };

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <div className={css.avatarPlaceholderText}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                </div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <Tooltip id="working-as-teacher-tooltip" openOnClick={true} style={toolTipStyle} />
              <Tooltip
                id="household-annual-income-tooltip"
                openOnClick={true}
                style={toolTipStyle}
              />
              <Tooltip id="credit-score-tooltip" openOnClick={true} style={toolTipStyle} />
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.yourProfilePicture" />
                </h3>
                <Field
                  accept={ACCEPT_IMAGES}
                  id="profileImage"
                  name="profileImage"
                  label={chooseAvatarLabel}
                  type="file"
                  form={null}
                  uploadImageError={uploadImageError}
                  disabled={uploadInProgress}
                >
                  {fieldProps => {
                    const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`profileImage`, file);
                      form.blur(`profileImage`);
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onImageUpload({ id: tempId, file });
                      }
                    };

                    let error = null;

                    if (isUploadImageOverLimitError(uploadImageError)) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                        </div>
                      );
                    } else if (uploadImageError) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                        </div>
                      );
                    }

                    return (
                      <div className={css.uploadAvatarWrapper}>
                        <label className={css.label} htmlFor={id}>
                          {label}
                        </label>
                        <input
                          accept={accept}
                          id={id}
                          name={name}
                          className={css.uploadAvatarInput}
                          disabled={disabled}
                          onChange={onChange}
                          type={type}
                        />
                        {error}
                      </div>
                    );
                  }}
                </Field>
                <div className={css.tip}>
                  <FormattedMessage id="ProfileSettingsForm.tip" />
                </div>
                <div className={css.fileInfo}>
                  <FormattedMessage id="ProfileSettingsForm.fileInfo" />
                </div>
              </div>
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.yourName" />
                </h3>
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="firstName"
                    name="firstName"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastName}
                    type="text"
                    id="lastName"
                    name="lastName"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>
              </div>
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.bioHeading" />
                </h3>
                <FieldTextInput
                  type="textarea"
                  id="bio"
                  name="bio"
                  label={bioLabel}
                  placeholder={bioPlaceholder}
                />
                <p className={css.bioInfo}>
                  <FormattedMessage id="ProfileSettingsForm.bioInfo" />
                </p>
              </div>
              <div>
                <div>
                  <FieldSelect
                    id={formId ? `${formId}.genderPronoun` : 'genderPronoun'}
                    name="genderPronoun"
                    className={css.field}
                    label={genderPronounLabel}
                  >
                    <option disabled value="">
                      {genderPronounPlaceholder}
                    </option>
                    <option value={'He/Him'}>{heLabel}</option>
                    <option value={'She/Hers'}>{sheLabel}</option>
                    <option value={'They/Them'}>{theyLabel}</option>
                    <option value={'Other'}>{otherLabel}</option>
                  </FieldSelect>

                  {values?.genderPronoun === 'Other' ? (
                    <FieldTextInput
                      className={css.field}
                      type="text"
                      id={formId ? `${formId}.otherGender` : 'otherGender'}
                      name="otherGender"
                      autoComplete="otherGender"
                      label={otherGenderLabel}
                      placeholder={otherGenderPlaceholder}
                    />
                  ) : null}
                  <FieldPhoneNumberInput
                    className={css.field}
                    type="text"
                    id={'phoneNumber'}
                    name="phoneNumber"
                    autoComplete="phoneNumber"
                    label={phoneNumberLabel}
                    placeholder={'(xxx) xxx-xxxx'}
                    validate={phoneNumberRequired}
                  />

                  <FieldSelect
                    className={css.field}
                    name={'workingAsTeacher'}
                    id={formId ? `${formId}.workingAsTeacher` : 'workingAsTeacher'}
                    label={workingAsTeacherLabel}
                    validate={workingAsTeacherRequired}
                  >
                    <option disabled value="">
                      {workingAsTeacherPlaceholder}
                    </option>
                    <option value={'yes'}>{workingAsTeacherOptionYes}</option>
                    <option value={'incomingTeacher'}>
                      {workingAsTeacherOptionIncomingTeacher}
                    </option>
                    <option value={'no'}>{workingAsTeacherOptionNo}</option>
                  </FieldSelect>

                  <FieldSelect
                    className={css.field}
                    name={'classifiedToTeacherProgram'}
                    id={
                      formId ? `${formId}.classifiedToTeacherProgram` : 'classifiedToTeacherProgram'
                    }
                    label={classifiedToTeacherProgramLabel}
                    validate={classifiedToTeacherProgramRequired}
                  >
                    <option disabled value="">
                      {classifiedToTeacherProgramPlaceholder}
                    </option>
                    <option value={'Yes'}>{classifiedToTeacherProgramOptionYes}</option>
                    <option value={'No'}>{classifiedToTeacherProgramOptionNo}</option>
                  </FieldSelect>

                  <FieldSelect
                    className={css.field}
                    name={'startYearWithOUSD'}
                    id={formId ? `${formId}.startYearWithOUSD` : 'startYearWithOUSD'}
                    label={startYearWithOUSDLabel}
                    validate={startYearWithOUSDRequired}
                  >
                    <option disabled value="">
                      {startYearWithOUSDPlaceholder}
                    </option>
                    {range(1950, 2028)
                      .reverse()
                      .map(y => (
                        <option value={y}>{y.toString()}</option>
                      ))}
                  </FieldSelect>

                  <FieldTextInput
                    className={css.field}
                    type="text"
                    id={'OUSDEmployerID'}
                    name="OUSDEmployerID"
                    autoComplete="OUSDEmployerID"
                    label={OUSDEmployerIDLabel}
                    placeholder={'0'}
                    validate={employeeIdValidator}
                  />
                  <FieldCheckbox
                    id={formId ? `${formId}.dontHaveEmployerID"` : 'dontHaveEmployerID'}
                    name="dontHaveEmployerID"
                    label={dontHaveEmployerIDLabel}
                    value={true}
                    className={css.checkbox}
                  />

                  <FieldSelect
                    className={css.field}
                    name={'moving'}
                    id={formId ? `${formId}.moving` : 'moving'}
                    label={movingLabel}
                    validate={movingRequired}
                  >
                    <option disabled value="">
                      {movingPlaceholder}
                    </option>
                    <option value={'now'}>{movingOptionNow}</option>
                    <option value={'nextFewMonths'}>{movingOptionNextFewMonths}</option>
                    <option value={'later'}>{movingOptionLater}</option>
                  </FieldSelect>

                  <FieldSelect
                    className={css.field}
                    name={'unitSize'}
                    id={formId ? `${formId}.unitSize` : 'unitSize'}
                    label={unitSizeLabel}
                  >
                    <option disabled value="">
                      {unitSizePlaceholder}
                    </option>
                    <option value={'studio'}>{unitSizeOptionStudio}</option>
                    <option value={'oneBedroom'}>{unitSizeOptionOneBedroom}</option>
                    <option value={'twoBedrooms'}>{unitSizeOptionTwoBedrooms}</option>
                    <option value={'threeBedroomsOrMore'}>
                      {unitSizeOptionThreeBedroomsOrMore}
                    </option>
                  </FieldSelect>

                  <FieldCheckboxGroup
                    className={css.neighborhoods}
                    id={formId ? `${formId}.neighborhoods` : 'neighborhoods'}
                    name={'neighborhoods'}
                    label={neighborhoodsLabel}
                    options={[
                      {
                        key: 'East Oakland',
                        label: 'East Oakland',
                      },
                      {
                        key: 'Downtown',
                        label: 'Downtown',
                      },
                      {
                        key: 'West Oakland',
                        label: 'West Oakland',
                      },
                      {
                        key: 'North Oakland',
                        label: 'North Oakland',
                      },
                      {
                        key: 'Oakland Hills',
                        label: 'Oakland Hills',
                      },
                    ]}
                  />

                  <FieldTextInput
                    className={css.field}
                    type="text"
                    id={formId ? `${formId}.householdAnnualIncome` : 'householdAnnualIncome'}
                    name="householdAnnualIncome"
                    autoComplete="householdAnnualIncome"
                    label={householdAnnualIncomeLabel}
                    placeholder={householdAnnualIncomePlaceholder}
                    validate={householdAnnualIncomeValidator}
                  />

                  <FieldCheckbox
                    id={
                      formId
                        ? `${formId}.householdAnnualIncomeRatherNotAnswer"`
                        : 'householdAnnualIncomeRatherNotAnswer'
                    }
                    name="householdAnnualIncomeRatherNotAnswer"
                    label={householdAnnualIncomeRatherNotAnswerLabel}
                    value={true}
                    className={css.checkbox}
                  />

                  <FieldTextInput
                    className={css.field}
                    type="text"
                    id={formId ? `${formId}.creditScore` : 'creditScore'}
                    name="creditScore"
                    autoComplete="creditScore"
                    label={creditScoreLabel}
                    placeholder={creditScorePlaceholder}
                    validate={creditScoreValidator}
                  />

                  <FieldCheckbox
                    id={
                      formId
                        ? `${formId}.creditScoreRatherNotAnswer"`
                        : 'creditScoreRatherNotAnswer'
                    }
                    name="creditScoreRatherNotAnswer"
                    label={creditScoreRatherNotAnswerLabel}
                    value={true}
                    className={css.checkbox}
                  />
                </div>
              </div>
              {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="ProfileSettingsForm.saveChanges" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
