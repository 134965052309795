import React from 'react';
import LogoSvg from './trioplus.small.png'; // Update the import path if necessary

const IconLogo = (props) => {
  const { className, ...rest } = props;

  return (
   <img className={className} src={LogoSvg} alt="TrioPlus" {...rest} />
  )
};

export default IconLogo;
