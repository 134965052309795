import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>At TRiO Plus,<br /> we value your privacy and are committed to protecting your personal information. This privacy policy explains how we collect, use, and disclose your information when you use our website, products, and services.</p>

      <p>Information We Collect<br />We may collect personal information such as your name, email address, and phone number when you sign up for our services or contact us for support. We may also collect non-personal information such as your IP address, browser type, and operating system.</p>

      <p>How We Use Your Information<br/>We may use your personal information to provide our services, communicate with you, and improve our products and services. We may also use your non-personal information for analytics and to improve our website and services.</p>

      <p>Sharing Your Information with Third Parties<br/>We may share your personal information with trusted third-party service providers who help us provide our services, such as hosting providers and payment processors. We may also share your information with government or law enforcement officials if required by law or to protect our rights and property.</p>

      <p>Protecting Your Information<br/>We take reasonable measures to protect your personal information from unauthorized access, disclosure, and use. We use industry-standard security technologies such as encryption and firewalls to protect your information.</p>

      <p>Your Choices<br/>You have the right to access, correct, and delete your personal information. You can also opt-out of receiving promotional emails and other communications from us. To communicate with us about your choices, please email us at compliance@trioplus.org.</p>

      <p>Changes to This Privacy Policy<br/>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on our website.</p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
