const defaultSchoolsLocations = [
  {
    id: 'none',
    label: '',
    schools: [
      {
        id: 'madison-park-academy',
        name: 'Madison Park Academy',
        address: '400 Capistrano Dr, Oakland, CA 94603',
        zipcode: '94603',
        search:
          '/s?address=400%20Capistrano%20Drive%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.75080444%2C-122.15458739%2C37.70542288%2C-122.20001861&origin=37.728117%2C-122.177303',
      },
      {
        id: 'aspire-lionel-wilson-college-preparatory-academy',
        name: 'Aspire Lionel Wilson College Preparatory Academy',
        address: '400 105th Ave, Oakland, CA 94603',
        zipcode: '94603',
        search:
          '/s?address=400%20105th%20Avenue%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.75225344%2C-122.15721295%2C37.70687188%2C-122.20264505&origin=37.729566%2C-122.179929',
      },
      {
        id: 'lodestar-a-lighthouse-community-public-school',
        name: 'Lodestar, A Lighthouse Community Public School',
        address: '701 105th Ave, Oakland, CA 94603',
        zipcode: '94603',
        search:
          '/s?address=701%20105th%20Avenue%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.75686044%2C-122.15524453%2C37.71147888%2C-122.20067947&origin=37.734173%2C-122.177962',
      },
      {
        id: 'stonehurst-esperanza-elementary-school',
        name: 'Stonehurst/Esperanza Elementary School',
        address: '10315 E St, Oakland, CA 94603',
        zipcode: '94603',
        search:
          '/s?address=10315%20E%20Street%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.75982844%2C-122.15119962%2C37.71444688%2C-122.19663638&origin=37.737141%2C-122.173918',
      },
      {
        id: 'fred-t-korematsu-discovery-academy-KDA',
        name: 'Fred T. Korematsu Discovery Academy - KDA',
        address: '10315 E St, Oakland, CA 94603',
        zipcode: '94603',
        search:
          '/s?address=10315%20E%20Street%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.75982844%2C-122.15119962%2C37.71444688%2C-122.19663638&origin=37.737141%2C-122.173918',
      },
      {
        id: 'brookfield-elementary-school',
        name: 'Brookfield Elementary School',
        address: '401 Jones Ave, Oakland, CA 94603',
        zipcode: '94603',
        search:
          '/s?address=401%20Jones%20Avenue%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.75751644%2C-122.16658633%2C37.71213488%2C-122.21202167&origin=37.734829%2C-122.189304',
      },
      {
        id: 'aspire-monarch-academy',
        name: 'Aspire Monarch Academy',
        address: '1445 101st Ave, Oakland, CA 94603',
        zipcode: '94603',
        search:
          '/s?address=1445%20101st%20Avenue%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.76541044%2C-122.14503691%2C37.72002888%2C-122.19047709&origin=37.742723%2C-122.167757',
      },
      {
        id: 'elmhurst-united-middle-school',
        name: 'Elmhurst United Middle School',
        address: '1800 98th Ave, Oakland, CA 94603',
        zipcode: '94603',
        search:
          '/s?address=1800%2098th%20Avenue%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.76878644%2C-122.14271387%2C37.72340488%2C-122.18815613&origin=37.746099%2C-122.165435',
      },
      {
        id: 'eaetin-kidango-Reach-cox-academy',
        name: 'Eaetin/Kidango/Reach Cox Academy',
        address: '9860 Sunnyside St, Oakland, CA 94603',
        zipcode: '94603',
        search:
          '/s?address=9860%20Sunnyside%20Street%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.76919744%2C-122.13862175%2C37.72381588%2C-122.18406425&origin=37.74651%2C-122.161343',
      },
      {
        id: 'united-nation-child-development-center',
        name: 'United Nation Child Development Center',
        address: '1025 4th Ave, Oakland, CA 94606',
        zipcode: '94606',
        search:
          '/s?address=1025%204th%20Avenue%2C%20Oakland%2C%20California%2094606%2C%20United%20States&bounds=37.81721643%2C-122.23470999%2C37.77183487%2C-122.28018201&origin=37.794529%2C-122.257446',
      },
    ],
  },
  {
    id: 'elementary-schools',
    label: 'Elementary schools',
    schools: [
      {
        id: 'acorn-woodland',
        name: 'ACORN WOODLAND',
        address: '1025 81st Avenue, 94621',
        zipcode: '94621',
        search:
          '/s?address=1025%2081st%20Avenue%2C%20Oakland%2C%20California%2094621%2C%20United%20States&bounds=37.77620844%2C-122.16330459%2C37.73082688%2C-122.20875141&origin=37.753521%2C-122.186028',
      },
      {
        id: 'allendale',
        name: 'ALLENDALE',
        address: '3670 Penniman Avenue, 94619',
        zipcode: '94619',
        search:
          '/s?address=3670%20Penniman%20Avenue%2C%20Oakland%2C%20California%2094619%2C%20United%20States&bounds=37.81119643%2C-122.18196184%2C37.76581487%2C-122.22743016&origin=37.788509%2C-122.204696',
      },
      {
        id: 'bella-vista',
        name: 'BELLA VISTA',
        address: '1025 East 28th Street, 94610',
        zipcode: '94610',
        search:
          '/s?address=1025%20East%2028th%20Street%2C%20Oakland%2C%20California%2094610%2C%20United%20States&bounds=37.82289743%2C-122.21456724%2C37.77751587%2C-122.26004276&origin=37.80021%2C-122.237305',
      },
      {
        id: 'bridges-academy',
        name: 'BRIDGES ACADEMY',
        address: '1325 53rd Avenue, 94601',
        zipcode: '94601',
        search:
          '/s?address=1325%2053rd%20Avenue%2C%20Oakland%2C%20California%2094601%2C%20United%20States&bounds=37.78997043%2C-122.18462937%2C37.74458888%2C-122.23008463&origin=37.767283%2C-122.207357',
      },
      {
        id: 'brookfield',
        name: 'BROOKFIELD',
        address: '401 Jones Avenue, 94603 ',
        zipcode: '94603',
        search:
          '/s?address=401%20Jones%20Avenue%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.75751644%2C-122.16658633%2C37.71213488%2C-122.21202167&origin=37.734829%2C-122.189304',
      },
      {
        id: 'burckhalter',
        name: 'BURCKHALTER',
        address: '3994 Burckhalter Avenue, 94605',
        zipcode: '94605',
        search:
          '/s?address=3994%20Burckhalter%20Avenue%2C%20Oakland%2C%20California%2094605%2C%20United%20States&bounds=37.79791243%2C-122.14422792%2C37.75253088%2C-122.18968808&origin=37.775225%2C-122.166958',
      },
      {
        id: 'carl-munck',
        name: 'CARL MUNCK',
        address: '11900 Campus Drive, 94619',
        zipcode: '94619',
        search:
          '/s?address=11900%20Campus%20Drive%2C%20Oakland%2C%20California%2094619%2C%20United%20States&bounds=37.81776672%2C-122.15108857%2C37.7724346%2C-122.19651143&origin=37.795104%2C-122.1738',
      },
      {
        id: 'chabot',
        name: 'CHABOT',
        address: '6686 Chabot Road, 94618',
        zipcode: '94618',
        search:
          '/s?address=6686%20Chabot%20Road%2C%20Oakland%2C%20California%2094618%2C%20United%20States&bounds=37.872891%2C-122.22022128%2C37.8275263%2C-122.26571072&origin=37.850212%2C-122.242966',
      },
      {
        id: 'crocker-highlands',
        name: 'CROCKER HIGHLANDS',
        address: '525 Midcrest Road, 94610',
        zipcode: '94610',
        search:
          '/s?address=525%20Midcrest%20Road%2C%20Oakland%2C%20California%2094610%2C%20United%20States&bounds=37.83370143%2C-122.20445891%2C37.78831987%2C-122.24994109&origin=37.811014%2C-122.2272',
      },
      {
        id: 'east-oakland-pride',
        name: 'EAST OAKLAND PRIDE',
        address: '8000 Birch Street, 94621',
        zipcode: '94621',
        search:
          '/s?address=8000%20Birch%20St%2C%20Oakland%2C%20California%2094621%2C%20United%20States&bounds=37.78184943%2C-122.15409286%2C37.73646788%2C-122.19954314&origin=37.759162%2C-122.176818',
      },
      {
        id: 'emerson',
        name: 'EMERSON',
        address: '4803 Lawton Avenue, 94609',
        zipcode: '94609',
        search:
          '/s?address=4803%20Lawton%20Avenue%2C%20Oakland%2C%20California%2094609%2C%20United%20States&bounds=37.85680843%2C-122.23526479%2C37.81142687%2C-122.28076121&origin=37.834121%2C-122.258013',
      },
      {
        id: 'encompass-academy',
        name: 'ENCOMPASS ACADEMY',
        address: '1025 81st Avenue, 94621',
        zipcode: '94621',
        search:
          '/s?address=1025%2081st%20Avenue%2C%20Oakland%2C%20California%2094621%2C%20United%20States&bounds=37.77620844%2C-122.16330459%2C37.73082688%2C-122.20875141&origin=37.753521%2C-122.186028',
      },
      {
        id: 'esperanza',
        name: 'ESPERANZA',
        address: '10315 E Street, 94603',
        zipcode: '94603',
        search:
          '/s?address=10315%20E%20Street%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.75864767%2C-122.15119962%2C37.71562835%2C-122.19663638&origin=37.737141%2C-122.173918',
      },
      {
        id: 'franklin',
        name: 'FRANKLIN',
        address: '915 Foothill Boulevard, 94606',
        zipcode: '94606',
        search:
          '/s?address=915%20Foothill%20Boulevard%2C%20Oakland%2C%20California%2094606%2C%20United%20States&bounds=37.81500167%2C-122.2260223%2C37.77198235%2C-122.2714937&origin=37.793495%2C-122.248758',
      },
      {
        id: 'fred-t-korematsu-discovery-academy',
        name: 'FRED T. KOREMATSU DISCOVERY ACADEMY',
        address: '10315 E Street, 94603',
        zipcode: '94603',
        search:
          '/s?address=10315%20E%20Street%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.75864767%2C-122.15119962%2C37.71562835%2C-122.19663638&origin=37.737141%2C-122.173918',
      },
      {
        id: 'fruitvale',
        name: 'FRUITVALE',
        address: ' 3200 Boston Avenue, 94602',
        zipcode: '94602',
        search:
          '/s?address=3200%20Boston%20Avenue%2C%20Oakland%2C%20California%2094602%2C%20United%20States&bounds=37.81784467%2C-122.19091943%2C37.77482535%2C-122.23639257&origin=37.796338%2C-122.213656',
      },
      {
        id: 'garfield',
        name: 'GARFIELD',
        address: '1640 22nd Avenue, 94606',
        zipcode: '94606',
        search:
          '/s?address=1640%2022nd%20Avenue%2C%20Oakland%2C%20California%2094606%2C%20United%20States&bounds=37.80794667%2C-122.21304048%2C37.76492735%2C-122.25850752&origin=37.78644%2C-122.235774',
      },
      {
        id: 'glienview',
        name: 'GLENVIEW',
        address: '4215 La Cresta Avenue, 94602',
        zipcode: '94602',
        search:
          '/s?address=4215%20La%20Cresta%20Avenue%2C%20Oakland%2C%20California%2094602%2C%20United%20States&bounds=37.82715866%2C-122.19643956%2C37.78413934%2C-122.24191844&origin=37.805652%2C-122.219179',
      },
      {
        id: 'global-family',
        name: 'GLOBAL FAMILY',
        address: '2035 40th Avenue, 94601',
        zipcode: '94601',
        search:
          '/s?address=2035%2040th%20Avenue%2C%20Oakland%2C%20California%2094601%2C%20United%20States&bounds=37.80108167%2C-122.19067959%2C37.75806235%2C-122.23614241&origin=37.779575%2C-122.213411',
      },
      {
        id: 'grass-valley',
        name: 'GRASS VALLEY',
        address: '4720 Dunkirk Avenue, 94605',
        zipcode: '94605',
        search:
          '/s?address=4720%20Dunkirk%20Avenue%2C%20Oakland%2C%20California%2094605%2C%20United%20States&bounds=37.77440767%2C-122.10300878%2C37.73138835%2C-122.14845522&origin=37.752901%2C-122.125732',
      },
      {
        id: 'greenleaf',
        name: 'GREENLEAF',
        address: '6328 East 17th Street, 94621',
        zipcode: '94621',
        search:
          '/s?address=6328%20East%2017th%20Street%2C%20Oakland%2C%20California%2094621%2C%20United%20States&bounds=37.78602967%2C-122.17182521%2C37.74301035%2C-122.21727879&origin=37.764523%2C-122.194552',
      },
      {
        id: 'highland-community-school',
        name: 'HIGHLAND COMMUNITY SCHOOL',
        address: '8521 A Street, 94621',
        zipcode: '94621',
        search:
          '/s?address=8521%20A%20Street%2C%20Oakland%2C%20California%2094621%2C%20United%20States&bounds=37.77313867%2C-122.15651117%2C37.73011935%2C-122.20195683&origin=37.751632%2C-122.179234',
      },
      {
        id: 'hillcrest',
        name: 'HILLCREST',
        address: '30 Marguerite Drive, 94618',
        zipcode: '94618',
        search:
          '/s?address=30%20Marguerite%20Drive%2C%20Oakland%2C%20California%2094618%2C%20United%20States&bounds=37.86206966%2C-122.2100048%2C37.81905034%2C-122.2555052&origin=37.840563%2C-122.232755',
      },
      {
        id: 'hoover',
        name: 'HOOVER',
        address: '890 Brockhurst Street, 94608',
        zipcode: '94608',
        search:
          '/s?address=890%20Brockhurst%20Street%2C%20Oakland%2C%20California%2094608%2C%20United%20States&bounds=37.84490466%2C-122.2519211%2C37.80188534%2C-122.2974109&origin=37.823398%2C-122.274666',
      },
      {
        id: 'horace-mann',
        name: 'HORACE MANN',
        address: '5222 Ygnacio Avenue, 94601',
        zipcode: '94601',
        search:
          '/s?address=5222%20Ygnacio%20Avenue%2C%20Oakland%2C%20California%2094601%2C%20United%20States&bounds=37.79509882%2C-122.17974144%2C37.75247331%2C-122.22478456&origin=37.773789%2C-122.202263',
      },
      {
        id: 'international-community-school',
        name: 'INTERNATIONAL COMMUNITY SCHOOL',
        address: '2825 International Blvd, 94601',
        zipcode: '94601',
        search:
          '/s?address=2825%20International%20Boulevard%2C%20Oakland%2C%20California%2094601%2C%20United%20States&bounds=37.80150667%2C-122.20809346%2C37.75848735%2C-122.25355654&origin=37.78%2C-122.230825',
      },
      {
        id: 'joaquin-miller',
        name: 'JOAQUIN MILLER',
        address: '5525 Ascot Drive, 94611',
        zipcode: '94611',
        search:
          '/s?address=5525%20Ascot%20Drive%2C%20Oakland%2C%20California%2094611%2C%20United%20States&bounds=37.84039466%2C-122.17938349%2C37.79737534%2C-122.22487051&origin=37.818888%2C-122.202127',
      },
      {
        id: 'la-escuelita',
        name: 'LA ESCUELITA',
        address: '1050 Second Ave, 94606',
        zipcode: '94606',
        search:
          '/s?address=1050%202nd%20Avenue%2C%20Oakland%2C%20California%2094606%2C%20United%20States&bounds=37.81754867%2C-122.23567452%2C37.77452935%2C-122.28114748&origin=37.796042%2C-122.258411',
      },
      {
        id: 'laurel',
        name: 'LAUREL',
        address: '3750 Brown Avenue, 94619',
        zipcode: '94619',
        search:
          '/s?address=3750%20Brown%20Avenue%2C%20Oakland%2C%20California%2094619%2C%20United%20States&bounds=37.81435067%2C-122.17380951%2C37.77133135%2C-122.21928049&origin=37.792844%2C-122.196545',
      },
      {
        id: 'lincoln',
        name: 'LINCOLN',
        address: '225 11th Street, 94607',
        zipcode: '94607',
        search:
          '/s?address=225%2011th%20Street%2C%20Oakland%2C%20California%2094607%2C%20United%20States&bounds=37.82128966%2C-122.24423937%2C37.77827034%2C-122.28971463&origin=37.799783%2C-122.266977',
      },
      {
        id: 'lockwood-steam-academy',
        name: 'LOCKWOOD STEAM ACADEMY',
        address: '6701 International Boulevard, 94621',
        zipcode: '94621',
        search:
          '/s?address=6701%20International%20Boulevard%2C%20Oakland%2C%20California%2094621%2C%20United%20States&bounds=37.78277067%2C-122.16955222%2C37.73975135%2C-122.21500378&origin=37.761264%2C-122.192278',
      },
      {
        id: 'madison-park-academy-primary',
        name: 'MADISON PARK ACADEMY PRIMARY',
        address: '470 El Paseo Drive, 94603',
        zipcode: '94603',
        search:
          '/s?address=470%20El%20Paseo%20Drive%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.74952067%2C-122.15453042%2C37.70650135%2C-122.19996158&origin=37.728014%2C-122.177246',
      },
      {
        id: 'manzanita-community-school',
        name: 'MANZANITA COMMUNITY SCHOOL',
        address: '2409 East 27th Street, 94601',
        zipcode: '94601',
        search:
          '/s?address=2409%20East%2027th%20Street%2C%20Oakland%2C%20California%2094601%2C%20United%20States&bounds=37.81322867%2C-122.20212285%2C37.77020935%2C-122.24759315&origin=37.791722%2C-122.224858',
      },
      {
        id: 'manzanita-seed',
        name: 'MANZANITA SEED',
        address: '2409 East 27th Street, 94601',
        zipcode: '94601',
        search:
          '/s?address=2409%20East%2027th%20Street%2C%20Oakland%2C%20California%2094601%2C%20United%20States&bounds=37.81322867%2C-122.20212285%2C37.77020935%2C-122.24759315&origin=37.791722%2C-122.224858',
      },
      {
        id: 'markham',
        name: 'MARKHAM',
        address: '7220 Krause Avenue, 94605',
        zipcode: '94605',
        search:
          '/s?address=7220%20Krause%20Avenue%2C%20Oakland%2C%20California%2094605%2C%20United%20States&bounds=37.78706767%2C-122.15597789%2C37.74404835%2C-122.20143211&origin=37.765561%2C-122.178705',
      },
      {
        id: 'martin-luther-king-jr',
        name: 'MARTIN LUTHER KING, JR.',
        address: '960 10th Street, 94607',
        zipcode: '94607',
        search:
          '/s?address=960%2010th%20Street%2C%20Oakland%2C%20California%2094607%2C%20United%20States&bounds=37.82778666%2C-122.26028137%2C37.78476734%2C-122.30576063&origin=37.80628%2C-122.283021',
      },
      {
        id: 'melrose-leadership-academy',
        name: 'MELROSE LEADERSHIP ACADEMY',
        address: '4730 Fleming Avenue, 94619',
        zipcode: '94619',
        search:
          '/s?address=4730%20Fleming%20Avenue%2C%20Oakland%2C%20California%2094619%2C%20United%20States&bounds=37.80177467%2C-122.17491337%2C37.75875535%2C-122.22037663&origin=37.780268%2C-122.197645',
      },
      {
        id: 'montclair',
        name: 'MONTCLAIR',
        address: '1757 Mountain Boulevard, 94611',
        zipcode: '94611',
        search:
          '/s?address=1757%20Mountain%20Boulevard%2C%20Oakland%2C%20California%2094611%2C%20United%20States&bounds=37.85278566%2C-122.19071867%2C37.80976634%2C-122.23621333&origin=37.831279%2C-122.213466',
      },
      {
        id: 'oakland-acadey-of-knowledge',
        name: 'OAKLAND ACADEMY OF KNOWLEDGE',
        address: '8755 Fontaine Street, 94605',
        zipcode: '94605',
        search:
          '/s?address=8755%20Fontaine%20Street%2C%20Oakland%2C%20California%2094605%2C%20United%20States&bounds=37.78407667%2C-122.12969681%2C37.74105735%2C-122.17514919&origin=37.76257%2C-122.152423',
      },
      {
        id: 'peralta',
        name: 'PERALTA',
        address: '460 63rd Street, 94609',
        zipcode: '94609',
        search:
          '/s?address=460%2063rd%20Street%2C%20Oakland%2C%20California%2094609%2C%20United%20States&bounds=37.87112666%2C-122.23602601%2C37.82810734%2C-122.28153199&origin=37.84962%2C-122.258779',
      },
      {
        id: 'piedmont-avenue',
        name: 'PIEDMONT AVENUE',
        address: '4314 Piedmont Avenue, 94611',
        zipcode: '94611',
        search:
          '/s?address=4314%20Piedmont%20Avenue%2C%20San%20Francisco%20Bay%20Area%2C%20California%2094611%2C%20United%20States&bounds=37.85018766%2C-122.22589047%2C37.80716834%2C-122.27138353&origin=37.828681%2C-122.248637',
      },
      {
        id: 'prescott',
        name: 'PRESCOTT',
        address: '920 Campbell Street, 94607',
        zipcode: '94607',
        search:
          '/s?address=920%20Campbell%20Street%2C%20Oakland%2C%20California%2094607%2C%20United%20States&bounds=37.82934366%2C-122.27523489%2C37.78632434%2C-122.32071511&origin=37.807837%2C-122.297975',
      },
      {
        id: 'reach-academy',
        name: 'REACH ACADEMY',
        address: '9845 Bancroft Avenue, 94603',
        zipcode: '94603',
        search:
          '/s?address=9845%20Bancroft%20Avenue%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.76846667%2C-122.13858261%2C37.72544735%2C-122.18402539&origin=37.74696%2C-122.161304',
      },
      {
        id: 'redwood-heights',
        name: 'REDWOOD HEIGHTS',
        address: '4401 39th Avenue, 94619',
        zipcode: '94619',
        search:
          '/s?address=4401%2039th%20Avenue%2C%20Oakland%2C%20California%2094619%2C%20United%20States&bounds=37.81890766%2C-122.1634301%2C37.77588834%2C-122.2089039&origin=37.797401%2C-122.186167',
      },
      {
        id: 'sankofa-united',
        name: 'SANKOFA UNITED',
        address: '581 61st Street, 94609',
        zipcode: '94609',
        search:
          '/s?address=581%2061st%20Street%2C%20Oakland%2C%20California%2094609%2C%20United%20States&bounds=37.86841066%2C-122.24206785%2C37.82539134%2C-122.28757215&origin=37.846904%2C-122.26482',
      },
      {
        id: 'sequoia',
        name: 'SEQUOIA',
        address: '3730 Lincoln Avenue, 94602',
        zipcode: '94602',
        search:
          '/s?address=3730%20Lincoln%20Avenue%2C%20Oakland%2C%20California%2094602%2C%20United%20States&bounds=37.82378766%2C-122.1882556%2C37.78076834%2C-122.2337324&origin=37.802281%2C-122.210994',
      },
      {
        id: 'think-college-now',
        name: 'THINK COLLEGE NOW',
        address: '2825 International Boulevard, 94601',
        zipcode: '94601',
        search:
          '/s?address=2825%20International%20Boulevard%2C%20Oakland%2C%20California%2094601%2C%20United%20States&bounds=37.80150667%2C-122.20809346%2C37.75848735%2C-122.25355654&origin=37.78%2C-122.230825',
      },
      {
        id: 'thornhill',
        name: 'THORNHILL',
        address: '5880 Thornhill Drive, 94611',
        zipcode: '94611',
        search:
          '/s?address=5880%20Thornhill%20Drive%2C%20Oakland%2C%20California%2094611%2C%20United%20States&bounds=37.85742466%2C-122.18838824%2C37.81440534%2C-122.23388576&origin=37.835918%2C-122.211137',
      },
    ],
  },
  {
    id: 'middle-schools',
    label: 'MIDDLE SCHOOLS',
    schools: [
      {
        id: 'bret-harte',
        name: 'BRET HARTE',
        address: '3700 Coolidge Avenue, 94602',
        zipcode: '94602',
        search:
          '/s?address=3700%20Coolidge%20Avenue%2C%20Oakland%2C%20California%2094602%2C%20United%20States&bounds=37.82086366%2C-122.1837795%2C37.77784434%2C-122.2292545&origin=37.799357%2C-122.206517',
      },
      {
        id: 'claremont',
        name: 'CLAREMONT',
        address: '5750 College Avenue, 94618',
        zipcode: '94618',
        search:
          '/s?address=5750%20College%20Avenue%2C%20Oakland%2C%20California%2094618%2C%20United%20States&bounds=37.86724866%2C-122.22836421%2C37.82422934%2C-122.27386779&origin=37.845742%2C-122.251116',
      },
      {
        id: 'edna-brewer',
        name: 'EDNA BREWER',
        address: '3748 13th Avenue, 94610',
        zipcode: '94610',
        search:
          '/s?address=3748%2013th%20Avenue%2C%20Oakland%2C%20California%2094610%2C%20United%20States&bounds=37.82503866%2C-122.20640022%2C37.78201934%2C-122.25187778&origin=37.803532%2C-122.229139',
      },
      {
        id: 'elmhurst-united',
        name: 'ELMHURST UNITED',
        address: '1800 98th Avenue, 94603',
        zipcode: '94603',
        search:
          '/s?address=1800%2098th%20Avenue%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.76760567%2C-122.14271387%2C37.72458635%2C-122.18815613&origin=37.746099%2C-122.165435',
      },
      {
        id: 'frick-united-academy-of-language',
        name: 'FRICK UNITED ACADEMY OF LANGUAGE',
        address: '2845 64th Avenue, 94605',
        zipcode: '94605',
        search:
          '/s?address=2845%2064th%20Avenue%2C%20Oakland%2C%20California%2094605%2C%20United%20States&bounds=37.79295267%2C-122.16137909%2C37.74993335%2C-122.20683691&origin=37.771446%2C-122.184108',
      },
      {
        id: 'montera',
        name: 'MONTERA',
        address: '5555 Ascot Drive, 94611',
        zipcode: '94611',
        search:
          '/s?address=5555%20Ascot%20Drive%2C%20Oakland%2C%20California%2094611%2C%20United%20States&bounds=37.84127166%2C-122.17955022%2C37.79825234%2C-122.22503778&origin=37.819765%2C-122.202294',
      },
      {
        id: 'roosevelt',
        name: 'ROOSEVELT',
        address: '1926 19th Avenue, 94606',
        zipcode: '94606',
        search:
          '/s?address=19th%20Avenue%2C%20Oakland%2C%20California%2094606%2C%20United%20States&bounds=37.81285367%2C-122.21368297%2C37.76983435%2C-122.25915303&origin=37.791347%2C-122.236418',
      },
      {
        id: 'united-for-success',
        name: 'UNITED FOR SUCCESS',
        address: '2101 35th Avenue, 94601',
        zipcode: '94601',
        search:
          '/s?address=2101%2035th%20Avenue%2C%20Oakland%2C%20California%2094601%2C%20United%20States&bounds=37.80633867%2C-122.19372397%2C37.76331935%2C-122.23919003&origin=37.784832%2C-122.216457',
      },
      {
        id: 'urban-promise-academy',
        name: 'URBAN PROMISE ACADEMY',
        address: '3031 East 18th Street, 94601',
        zipcode: '94601',
        search:
          '/s?address=3031%20East%2018th%20Street%2C%20Oakland%2C%20California%2094601%2C%20United%20States&bounds=37.80352167%2C-122.20177884%2C37.76050235%2C-122.24724316&origin=37.782015%2C-122.224511',
      },
      {
        id: 'westlake',
        name: 'WESTLAKE',
        address: '2629 Harrison Street, 94612',
        zipcode: '94612',
        search:
          '/s?address=2629%20Harrison%20Street%2C%20Oakland%2C%20California%2094612%2C%20United%20States&bounds=37.83598266%2C-122.23812684%2C37.79296334%2C-122.28361116&origin=37.814476%2C-122.260869',
      },
      {
        id: 'west-oakland-middle',
        name: 'WEST OAKLAND MIDDLE',
        address: '991 14th Street, 94607',
        zipcode: '94607',
        search:
          '/s?address=991%2014th%20Street%2C%20Oakland%2C%20California%2094607%2C%20United%20States&bounds=37.82955266%2C-122.25939583%2C37.78653334%2C-122.30487617&origin=37.808046%2C-122.282136',
      },
    ],
  },
  {
    id: 'high-schools',
    label: 'HIGH SCHOOLS',
    schools: [
      {
        id: 'castlemont-high-school',
        name: 'CASTLEMONT HIGH SCHOOL',
        address: '8601 MacArthur Boulevard, 94605',
        zipcode: '94605',
        search:
          '/s?address=8601%20Macarthur%20Boulevard%2C%20Oakland%2C%20California%2094605%2C%20United%20States&bounds=37.77981767%2C-122.14056212%2C37.73679835%2C-122.18601188&origin=37.758311%2C-122.163287',
      },
      {
        id: 'coliseum-college-prep',
        name: 'COLISEUM COLLEGE PREP',
        address: '1390 66th Avenue, 94621',
        zipcode: '94621',
        search:
          '/s?address=1390%2066th%20Avenue%2C%20Oakland%2C%20California%2094621%2C%20United%20States&bounds=37.78317367%2C-122.17055609%2C37.74015435%2C-122.21600791&origin=37.761667%2C-122.193282',
      },
      {
        id: 'dewey-academy',
        name: 'DEWEY ACADEMY',
        address: '1111 Second Avenue, 94606',
        zipcode: '94606',
        search:
          '/s?address=1111%202nd%20Avenue%2C%20Oakland%2C%20California%2094606%2C%20United%20States&bounds=37.81838567%2C-122.23544426%2C37.77536635%2C-122.28091774&origin=37.796879%2C-122.258181',
      },
      {
        id: 'fremont-high-school',
        name: 'FREMONT HIGH SCHOOL',
        address: '4610 Foothill Boulevard, 94601',
        zipcode: '94601',
        search:
          '/s?address=4610%20Foothill%20Boulevard%2C%20Oakland%2C%20California%2094601%2C%20United%20States&bounds=37.79523767%2C-122.18645038%2C37.75221835%2C-122.23190962&origin=37.773731%2C-122.20918',
      },
      {
        id: 'life-academy',
        name: 'LIFE ACADEMY',
        address: '2101 35th Avenue, 94601',
        zipcode: '94601',
        search:
          '/s?address=2101%2035th%20Avenue%2C%20Oakland%2C%20California%2094601%2C%20United%20States&bounds=37.80633867%2C-122.19372397%2C37.76331935%2C-122.23919003&origin=37.784832%2C-122.216457',
      },
      {
        id: 'madison-park-academy-high-school',
        name: 'MADISON PARK ACADEMY',
        address: '400 Capistrano Drive, 94603',
        zipcode: '94603',
        search:
          '/s?address=400%20Capistrano%20Drive%2C%20Oakland%2C%20California%2094603%2C%20United%20States&bounds=37.74962367%2C-122.15458739%2C37.70660435%2C-122.20001861&origin=37.728117%2C-122.177303',
      },
      {
        id: 'mcclymonds-high-school',
        name: 'MCCLYMONDS HIGH SCHOOL',
        address: '2607 Myrtle Street, 94607',
        zipcode: '94607',
        search:
          '/s?address=2607%20Myrtle%20Street%2C%20Oakland%2C%20California%2094607%2C%20United%20States&bounds=37.84041166%2C-122.25586748%2C37.79739234%2C-122.30135452&origin=37.818905%2C-122.278611',
      },
      {
        id: 'metwest',
        name: 'METWEST',
        address: '314 East 10th Street, 94606',
        zipcode: '94606',
        search:
          '/s?address=314%20East%2010th%20Street%2C%20Oakland%2C%20California%2094606%2C%20United%20States&bounds=37.81679067%2C-122.23511675%2C37.77377135%2C-122.28058925&origin=37.795284%2C-122.257853',
      },
      {
        id: 'oakland-high-school',
        name: 'OAKLAND HIGH SCHOOL',
        address: '1023 MacArthur Boulevard, 94610',
        zipcode: '94610',
        search:
          '/s?address=1023%20Macarthur%20Boulevard%2C%20Oakland%2C%20California%2094610%2C%20United%20States&bounds=37.82587866%2C-122.21382796%2C37.78285934%2C-122.25930604&origin=37.804372%2C-122.236567',
      },
      {
        id: 'oakland-international',
        name: 'OAKLAND INTERNATIONAL',
        address: '4521 Webster Street, 94609',
        zipcode: '94609',
        search:
          '/s?address=4521%20Webster%20Street%2C%20Oakland%2C%20California%2094609%2C%20United%20States&bounds=37.85524566%2C-122.23796591%2C37.81222634%2C-122.28346209&origin=37.833739%2C-122.260714',
      },
      {
        id: 'oakland-technical',
        name: 'OAKLAND TECHNICAL',
        address: '4351 Broadway, 94611',
        zipcode: '94611',
        search:
          '/s?address=4351%20Broadway%2C%20Oakland%2C%20California%2094611%2C%20United%20States&bounds=37.85314866%2C-122.23255055%2C37.81012934%2C-122.27804545&origin=37.831642%2C-122.255298',
      },
      {
        id: 'oakland-tech-fashion-academy',
        name: 'Oakland Tech Fashion Academy',
        address: '5263 Broadway Terrace, 94618',
        zipcode: '94618',
        search:
          '/s?address=5263%20Broadway%20Terrace%2C%20Oakland%2C%20California%2094618%2C%20United%20States&bounds=37.85825766%2C-122.22630698%2C37.81523834%2C-122.27180502&origin=37.836751%2C-122.249056',
      },
      {
        id: 'ralph-j-bunche-academy',
        name: 'RALPH J. BUNCHE ACADEMY',
        address: '991 - 14th Street, Building A, 94607',
        zipcode: '94607',
        search:
          '/s?address=991%2014th%20Street%2C%20Oakland%2C%20California%2094607%2C%20United%20States&bounds=37.82955266%2C-122.25939583%2C37.78653334%2C-122.30487617&origin=37.808046%2C-122.282136',
      },
      {
        id: 'rudsdale-continuation-school-incl-newcomer',
        name: 'RUDSDALE CONTINUATION SCHOOL INCL. NEWCOMER',
        address: '8251 Fontaine Street, 94605',
        zipcode: '94605',
        search:
          '/s?address=8251%20Fontaine%20Street%2C%20Oakland%2C%20California%2094605%2C%20United%20States&bounds=37.78748167%2C-122.13102777%2C37.74446235%2C-122.17648223&origin=37.765975%2C-122.153755',
      },
      {
        id: 'skyline-high-school',
        name: 'SKYLINE HIGH SCHOOL',
        address: '12250 Skyline Boulevard, 94619',
        zipcode: '94619',
        search:
          '/s?address=12250%20Skyline%20Boulevard%2C%20Oakland%2C%20California%2094619%2C%20United%20States&bounds=37.81947266%2C-122.13917593%2C37.77645334%2C-122.18465007&origin=37.797966%2C-122.161913',
      },
      {
        id: 'street-academy',
        name: 'STREET ACADEMY',
        address: '417 29th Street, 94609',
        zipcode: '94609',
        search:
          '/s?address=417%2029th%20Street%2C%20Oakland%2C%20California%2094609%2C%20United%20States&bounds=37.83896566%2C-122.24281593%2C37.79594634%2C-122.28830207&origin=37.817459%2C-122.265559',
      },
    ],
  },
  {
    id: 'alternative-education',
    label: 'ALTERNATIVE EDUCATION',
    schools: [
      {
        id: 'gateway-to-college-at-laney-college',
        name: 'GATEWAY TO COLLEGE AT LANEY COLLEGE',
        address: '900 Fallon Street, 94607',
        zipcode: '94607',
        search:
          '/s?address=900%20Fallon%20Street%2C%20Oakland%2C%20California%2094607%2C%20United%20States&bounds=37.81709367%2C-122.23955566%2C37.77407435%2C-122.28502834&origin=37.795587%2C-122.262292',
        type: {
          id: 'alternative-education',
          label: 'ALTERNATIVE EDUCATION',
        },
      },
      {
        id: 'sojourner-truth',
        name: 'SOJOURNER TRUTH',
        address: '8251 Fontaine Street, 94605',
        zipcode: '94605',
        search:
          '/s?address=8251%20Fontaine%20Street%2C%20Oakland%2C%20California%2094605%2C%20United%20States&bounds=37.78748167%2C-122.13102777%2C37.74446235%2C-122.17648223&origin=37.765975%2C-122.153755',
      },
    ],
  },
  {
    id: 'special-education',
    label: 'SPECIAL EDUCATION',
    schools: [
      {
        id: 'burbank-prescool-and-diagnostic-center',
        name: 'BURBANK PRESCHOOL & DIAGNOSTIC CENTER',
        address: '3550 64th Avenue, 94605',
        zipcode: '94605',
        search:
          '/s?address=3550%2064th%20Avenue%2C%20Oakland%2C%20California%2094605%2C%20United%20States&bounds=37.79675167%2C-122.15450092%2C37.75373235%2C-122.19996108&origin=37.775245%2C-122.177231',
      },
      {
        id: 'young-adult-program',
        name: 'YOUNG ADULT PROGRAM',
        address: '915 54th Street, 94608',
        zipcode: '94608',
        search:
          '/s?address=915%2054th%20Street%2C%20Oakland%2C%20California%2094608%2C%20United%20States&bounds=37.85953266%2C-122.25188259%2C37.81651334%2C-122.29738141&origin=37.838026%2C-122.274632',
      },
      {
        id: 'career-transition-services',
        name: 'CAREER TRANSITION SERVICES',
        address: '915 54th Street, 94608',
        zipcode: '94608',
        search:
          '/s?address=915%2054th%20Street%2C%20Oakland%2C%20California%2094608%2C%20United%20States&bounds=37.85953266%2C-122.25188259%2C37.81651334%2C-122.29738141&origin=37.838026%2C-122.274632',
      },
    ],
  },
];
export default defaultSchoolsLocations;
