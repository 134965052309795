import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, TIME_SLOT_DAY } from '../../util/types';

import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldTextInput, FieldDateInput } from '../../components';
import css from './EditListingDatesForm.module.css';
import moment from 'moment';

const { UUID } = sdkTypes;

const identity = v => v;

const { Money } = sdkTypes;

export const EditListingDatesFormComponent = props => (

  <FinalForm
    {...props}
    render={formRenderProps => {
      function handleActive(index) {
        setActiveInput(index);
      }

      function handleBlur(index) {
        if (activeInput === index) {
          setActiveInput(0);
        }
      }
      const [activeInput, setActiveInput] = useState(0);
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;
      
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const inputDateProps = {
        name: 'inputDate',
        useMobileMargins: false,
        id: `AvailableTimeSlotsDateInputForm.inputDate`,
        label: 'Input Date',
        placeholderText: moment().format('ddd, MMMM D'),
        format: identity,
        timeSlots: [],
        onFocus: () => handleActive(1),
        onBlur: () => handleBlur(1)
      }

      const activeDateProps = {
        name: 'activeDate',
        useMobileMargins: false,
        id: `AvailableTimeSlotsDateInputForm.activeDate`,
        label: 'Active Date',
        placeholderText: moment().format('ddd, MMMM D'),
        format: identity,
        timeSlots: [],
        onFocus: () => handleActive(2),
        onBlur: () => handleBlur(2),
      }

      const leaseSignDateProps = {
        name: 'leaseSignDate',
        useMobileMargins: false,
        id: `AvailableTimeSlotsDateInputForm.leaseSignDate`,
        label: 'Lease Sign Date',
        placeholderText: moment().format('ddd, MMMM D'),
        format: identity,
        timeSlots: [],
        onFocus: () => handleActive(3),
        onBlur: () => handleBlur(3),
      }

      const leaseStartDateProps = {
        name: 'leaseStartDate',
        useMobileMargins: false,
        id: `AvailableTimeSlotsDateInputForm.leaseStartDate`,
        label: 'Lease Start Date',
        placeholderText: moment().format('ddd, MMMM D'),
        format: identity,
        timeSlots: [],
        onFocus: () => handleActive(4),
        onBlur: () => handleBlur(4),
      }

      const leaseEndDateProps = {
        name: 'leaseEndDate',
        useMobileMargins: false,
        id: `AvailableTimeSlotsDateInputForm.leaseEndDate`,
        label: 'Lease End Date',
        placeholderText: moment().format('ddd, MMMM D'),
        format: identity,
        timeSlots: [],
        onFocus: () => handleActive(5),
        onBlur: () => handleBlur(5),
      }


      

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDatesForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDatesForm.showListingFailed" />
            </p>
          ) : null}
          <div className={ activeInput === 1 ? css.active: css.nonActive}>
            <FieldDateInput
            {...inputDateProps}
            />
          </div>
       

          <div  className={ activeInput === 2 ? css.active: css.nonActive}>
          <FieldDateInput
            {...activeDateProps}
          />
          </div>

        
          <div  className={ activeInput === 3 ? css.active: css.nonActive}>
          <FieldDateInput
            {...leaseSignDateProps}
          />
          </div>

          <div  className={ activeInput === 4 ? css.active: css.nonActive}>
          <FieldDateInput
            {...leaseStartDateProps}
          />
          </div>


          <div  className={ activeInput === 5 ? css.active: css.nonActive}>
          <FieldDateInput
            {...leaseEndDateProps}
          />
          </div>


          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {'Save Dates'}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDatesFormComponent.defaultProps = { fetchErrors: null };

EditListingDatesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingDatesFormComponent);
